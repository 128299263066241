import React, { useRef } from 'react';
import moment from 'moment';
import { Card } from 'primereact/card';
import { getImage, getImageFromBackend } from '../../../../utils/imageHelper';
import { SpeedDial } from 'primereact/speeddial';

import './News.scss';

function NewsItem({ news, updateNews, deleteNews, previewMode }) {
  const actions = [
    {
      label: 'Modifier',
      icon: 'pi pi-pencil',
      command: () => {
        updateNews();
      }
    },
    {
      label: 'Supprimer',
      icon: 'pi pi-trash',
      command: () => {
        deleteNews();
      }
    }
  ];

  const header = (
    <div className="p-3 pb-0">
      <img
        alt="Card"
        className="h-[180px] object-cover rounded"
        src={(previewMode ? news.previewUrl : news.image?.file) || getImage('img-ph.png')}
      />
    </div>
  );

  const title = (
    <div className="flex flex-row gap-x-2 items-center font-normal text-sm">
      <span>{moment(news.publication_date).format('DD/MM/YYYY')}</span>
      {news.source_label && <div className="h-1 w-1 bg-slate-700"></div>}
      {news.source_label && (
        <a
          href={news.source_url}
          target="_blank"
          rel="noreferrer"
          className="flex flex-row gap-x-2">
          <span>{news.source_label}</span>
          {news.source_url && <i className="pi pi-external-link"></i>}
        </a>
      )}
    </div>
  );

  const subtitle = <div className="text-xl font-semibold text-slate-900">{news.title}</div>;

  return (
    <Card
      title={title}
      subTitle={subtitle}
      header={header}
      className={`w-[400px] max-h-[500px] relative`}>
      <p className="m-0 mb-8 line-clamp-3 whitespace-pre-wrap">{news.hat}</p>
      {!previewMode && (
        <SpeedDial
          key="news-deletion"
          className="absolute bottom-5 right-3 text-xs "
          buttonClassName={'!h-9 !w-9'}
          model={actions}
          direction="up"
          rotateAnimation={false}
          showIcon="pi pi-ellipsis-v"
          hideIcon="pi pi-times"
        />
      )}
    </Card>
  );
}

export default NewsItem;
