import React from 'react';
import { InputText } from 'primereact/inputtext';

function SearchBar({ onChange, loading }) {
  return (
    <span className="p-input-icon-left">
      <i className={`pi ${loading ? 'pi-spin pi-spinner' : 'pi-search'}`} />
      <InputText placeholder="Rechercher" onChange={onChange} />
    </span>
  );
}

export default SearchBar;
