import React from 'react';
import { Button } from 'primereact/button';

function PageWrapper({ label, labelAction, action, children, detail }) {
  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex flex-row w-full justify-between items-center shadow px-6 py-4">
        <div className="flex flex-row gap-x-5 text-2xl font-semibold text-slate-800 items-center">
          <span
            onClick={() => labelAction(undefined)}
            className={`${detail && 'text-slate-600 cursor-pointer hover:text-slate-800'}`}>
            {label}
          </span>
          {detail && <i className="pi pi-arrow-right"></i>}
          {detail && <span>{detail.title}</span>}
        </div>

        <div className="flex flex-row gap-x-3">
          {action && (
            <Button
              key="additionnal"
              size="small"
              label={action.label}
              iconPos="left"
              text
              icon={`pi ${action.icon}`}
              onClick={action.onClick}
              className="text-slate-800 h-10"
            />
          )}
        </div>
      </div>
      <div className="overflow-y-scroll">
        <div className="flex flex-col px-6 pt-4 pb-20">{children}</div>
      </div>
    </div>
  );
}

export default PageWrapper;
