import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { useNavigate } from 'react-router-dom';
import { saveSession } from '../../../../utils/sessionHelper';
import { getImageFromBackend } from '../../../../utils/imageHelper';
import NavbarItem from './NavbarItem';
import { CONSTANT } from '../../../../utils/constants';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { filter } from 'underscore';

function Navbar({ isAdmin, availableApps = [] }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountContext, setAccountContext } = useAccountContext();
  const { navigationContext, setNavigationContext } = useNavigationContext();

  const handleSignOut = () => {
    setAccountContext(null);
    saveSession(null);
    navigate('/');
  };

  const navigateToApp = (app) => {
    setNavigationContext({
      acitveAppKey: app.key,
      acitveProgramId: undefined,
      acitveResourceId: undefined
    });
  };

  const filteredApps = useMemo(() => {
    return CONSTANT[accountContext.scope.slug].apps.filter((a) => {
      return availableApps.some((app) => {
        return app.slug === a.key;
      });
    });
  }, [availableApps]);

  const appList = isAdmin ? filteredApps : CONSTANT[accountContext.scope.slug].apps;

  return (
    <div className="h-full w-64 bg-slate-800 flex flex-col justify-between">
      <div className="flex flex-col">
        <div className="p-5 w-full flex flex-row items-center">
          <img
            className="h-10 mr-5"
            src={accountContext.scope.logo}
            alt={`${accountContext.scope.label}'s logo`}
          />
          <span
            className={`${CONSTANT[accountContext.scope.slug].titleSize} font-medium text-white`}>
            {accountContext.scope.label}
          </span>
        </div>
        <div className="flex flex-col">
          {appList.map((app) => (
            <NavbarItem
              key={app.key}
              icon={app.icon}
              label={app.label}
              handleNavigation={() => navigateToApp(app)}
              isActive={navigationContext.acitveAppKey === app.key}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-row w-full items-center justify-between text-slate-300 text-sm py-3 px-5">
        <span onClick={handleSignOut} className="cursor-pointer underline">
          Se déconnecter
        </span>
        <span>{`Version ${CONSTANT.version}`}</span>
      </div>
    </div>
  );
}

export default Navbar;
