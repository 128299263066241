import React from 'react';
import { getImage } from '../../../../utils/imageHelper';
import moment from 'moment';

function newsListItem({ news, handleClicknews }) {
  return (
    <div
      className="flex flex-row items-start py-5 px-3 gap-x-5 cursor-pointer hover:bg-slate-100"
      onClick={() => handleClicknews(news)}>
      <img
        className="h-32 w-32 object-cover shadow-md block rounded-md"
        src={news.image ? news.image.file : getImage('img-ph.png')}
        alt={news.image ? news.image.label : 'Non renseignée'}
      />

      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-y-1">
          <div className="text-xl font-bold text-slate-700 ">{news.title}</div>
          <div className="text-sm font-bold text-slate-700 ">
            {moment(news.creation_date).format('DD/MM/YYYY')}
          </div>
        </div>
      </div>
    </div>
  );
}

export default newsListItem;
