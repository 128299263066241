import React, { useEffect } from 'react';
import Welcome from './Welcome';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import Navbar from '../common/navbar/Navbar';
import AttendeesPage from './Attendees/AttendeesPage';
import EventPage from './Events/EventPage';
import './MaisonMousse.scss';

function Home() {
  const { navigationContext } = useNavigationContext();

  return (
    <div className="flex flex-row h-screen w-screen">
      <div className="min-w-64 w-64">
        <Navbar />
      </div>
      <div className="w-full h-auto overflow-scroll">
        <EventPage isActive={navigationContext.acitveAppKey === 'mmevent'} />
        <AttendeesPage isActive={navigationContext.acitveAppKey === 'attendees'} />
        <Welcome isActive={!navigationContext.acitveAppKey} />
      </div>
    </div>
  );
}

export default Home;
