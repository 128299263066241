import React from 'react';
import Welcome from './Welcome';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import Navbar from '../common/navbar/Navbar';
import ProjectPage from './Project/ProjectPage';
import ProductPage from './Product/ProductPage';
import './DejaDemain.scss';

function Home() {
  const { navigationContext } = useNavigationContext();

  return (
    <div className="flex flex-row h-screen w-screen">
      <div className="min-w-64 w-64">
        <Navbar />
      </div>
      <div className="w-full h-auto overflow-scroll">
        <ProjectPage isActive={navigationContext.acitveAppKey === 'ddproject'} />
        <ProductPage isActive={navigationContext.acitveAppKey === 'ddproduct'} />
        <Welcome isActive={!navigationContext.acitveAppKey} />
      </div>
    </div>
  );
}

export default Home;
