import React from 'react';
import Tag from './Tag';
import './Data.scss';

function MultipleDataTag({ label, tags, rounded }) {
  return (
    <div className={`flex flex-col gap-y-1 data-container w-fit`}>
      <span className="text-slate-500 text-sm">{label}</span>
      <div className="flex flex-row gap-x-3">
        {tags.map((t, i) => (
          <Tag
            key={i}
            tag={{
              label: t.label,
              bgColor: t.bgColor,
              icon: t.icon
            }}
            onClick={t.onClick}
            rounded={rounded}
          />
        ))}
        {(!tags || !tags.length) && (
          <p className="text-slate-400 italic font-extralight">Non renseignés</p>
        )}
      </div>
    </div>
  );
}

export default MultipleDataTag;
