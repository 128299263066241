import React from 'react';
import { CONSTANT } from '../../utils/constants';
import CustomIcon from '../CustomIcon';
import moment from 'moment';

function DocumentItem({ document, onUpdate }) {
  return (
    <div
      className="flex flex-row gap-x-3 items-center rounded border-2 bg-white min-w-64 p-2 pr-5 hover:shadow cursor-pointer"
      onClick={onUpdate}>
      <CustomIcon icon={'document'} className={`h-10 w-10 text-gray-600`} />
      <div className="flex flex-col gap-y-1">
        <p className="font-medium text-sm text-gray-800">
          {CONSTANT.caeterra.documentTypes.find((dt) => dt.value === document.type)?.label}
        </p>
        <span className="text-sm text-gray-500">
          {moment(document.last_update).format('DD/MM/YYYY')}
        </span>
      </div>
    </div>
  );
}

export default DocumentItem;
