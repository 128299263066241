import { Button } from 'primereact/button';
import React from 'react';

function Section({ label, actions, children }) {
  return (
    <div className="flex flex-col gap-y-5 mb-8">
      <div className="flex flex-row w-full justify-between items-center">
        <h3 className="text-slate-600 text-xl font-medium">{label}</h3>
        <div className="flex flex-row gap-x-3">
          {actions &&
            actions.map((action, index) => (
              <Button
                key={index}
                size="small"
                outlined
                rounded
                label={action.label}
                iconPos="left"
                icon={`pi ${action.icon}`}
                onClick={action.onClick}
                className="text-slate-800"
              />
            ))}
        </div>
      </div>
      {children}
    </div>
  );
}

export default Section;
