import React from 'react';
import CustomIcon from '../CustomIcon';

function Tag({ tag, onClick, rounded }) {
  if (!tag || !tag.label) return null;
  return (
    <div
      onClick={onClick ? onClick : () => console.log('Forbidden')}
      className={`py-1 px-3 ${
        rounded ? 'rounded-full' : 'rounded'
      } flex flex-row items-center gap-x-2 text-sm font-semibold w-fit overflow-hidden  ${
        tag.bgColor || 'bg-slate-600'
      } ${onClick ? 'cursor-pointer' : ''} text-slate-50`}>
      {tag.icon && (
        <CustomIcon icon={tag.icon} className="h-4 w-4 text-slate-50 p-0.5 min-w-[16px] -ml-1" />
      )}
      <p className="whitespace-nowrap text-ellipsis overflow-hidden"> {tag.label}</p>
    </div>
  );
}

export default Tag;
