import React, { useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import FormInput from '../../../../components/Form/FormInput';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { getDateTimeStringOrUndefined } from '../../../../utils/dateHelper';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';

function EventManager({
  event,
  eventManagerIsOpen,
  onCreate,
  isOpen,
  isUpdate,
  onUpdate,
  onClose,
  loading,
  propertyList
}) {
  const [placeName, setPlaceName] = useState('');
  // const [placeId, setPlaceId] = useState('');
  const [properties, setProperties] = useState([]);
  const [startDate, setStartDate] = useState(undefined);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [bookingUrl, setBookingUrl] = useState('');

  // Autocomplete
  /*
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const [query, setQuery] = useState('');
  */

  useEffect(() => {
    if (event) {
      setPlaceName(event.place_name);
      setProperties(event.properties);
      setStartDate(new Date(event.start_dt));
      setWebsiteUrl(event.website_url);
      setBookingUrl(event.booking_url);
      // setPlaceId(event.place_id);
      // setQuery(event.place_name);
    } else {
      setPlaceName('');
      setProperties([]);
      setStartDate(new Date(moment().set({ hour: 20, minute: 0, second: 0, millisecond: 0 })));
      setWebsiteUrl('');
      setBookingUrl('');
      // setPlaceId('');
      // setQuery('');
    }
  }, [event, eventManagerIsOpen]);

  /*
  const fetchAddressList = async (q) => {
    await privateFetch(
      'GET',
      'resources',
      `/cu/${accountContext.id}/address/autocomplete/?query=${q}`
    ).then((res) => {
      setFilteredAddresses(res);
    });
  };
  */

  const handleValidate = () => {
    const eventPayload = {
      place_name: placeName,
      start_dt: getDateTimeStringOrUndefined(startDate),
      properties: properties.map((p) => p.id),
      website_url: websiteUrl,
      booking_url: bookingUrl
      //place_id: placeId
    };
    isUpdate ? onUpdate(eventPayload) : onCreate(eventPayload);
  };

  const onPropertyChange = (e) => {
    let _properties = [...properties];

    if (e.checked) _properties.push(e.value);
    else _properties = _properties.filter((p) => p.id !== e.value.id);

    setProperties(_properties);
  };

  const formIsValid = () => {
    return /*placeId &&*/ startDate;
  };

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={isUpdate ? 'Valider la modification' : 'Valider la création'}
        className="w-full"
        disabled={!formIsValid()}
        onClick={handleValidate}
        severity="success"
        loading={loading}
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 600 }}
      visible={isOpen}
      position="right"
      onHide={() => onClose(false)}
      header={
        <h3 className="text-slate-800 text-2xl font-medium">{`${isUpdate ? 'Modifier' : 'Ajouter'} un évènement`}</h3>
      }>
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={"Lieu de l'évènement"} required isValid={placeName}>
            <InputText
              id="place_name"
              value={placeName}
              maxLength={300}
              placeholder="Veuillez saisir le nom du lieu"
              onChange={(e) => setPlaceName(e.target.value)}
            />
          </FormInput>
          <FormInput label={'Date de début'} required isValid={startDate}>
            <Calendar
              id="start_dt"
              onChange={(e) => setStartDate(e.value)}
              placeholder="Veuillez selectionner la date de début"
              value={startDate}
              locale="fr"
              showTime
              hourFormat="24"
              stepMinute={10}
            />
          </FormInput>

          <FormInput label={'Propriétés'}>
            <div className="flex flex-col gap-y-3 ml-2">
              {propertyList
                .filter((p) => p.type === 'bc_event')
                .map((p) => (
                  <div className="flex flex-row items-center">
                    <Checkbox
                      inputId={p.id}
                      name="property"
                      value={p}
                      onChange={onPropertyChange}
                      checked={properties.some((item) => item.id === p.id)}
                    />
                    <label htmlFor={p.id} className="ml-2 cursor-pointer">
                      {p.label}
                    </label>
                  </div>
                ))}
            </div>
          </FormInput>

          <FormInput label={"Site Web du lieu de l'évènement"}>
            <InputText
              id="website_url"
              value={websiteUrl}
              maxLength={300}
              placeholder="Veuillez saisir l'url du site web"
              onChange={(e) => setWebsiteUrl(e.target.value)}
            />
          </FormInput>
          <FormInput label={'Lien de réservation des tickets'}>
            <InputText
              id="booking_url"
              value={bookingUrl}
              maxLength={300}
              placeholder="Veuillez saisir l'url du site de vente des tickets"
              onChange={(e) => setBookingUrl(e.target.value)}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default EventManager;
