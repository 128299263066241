import React from 'react';
import { ReactComponent as News } from '../assets/icon/news.svg';
import { ReactComponent as User } from '../assets/icon/user.svg';
import { ReactComponent as Product } from '../assets/icon/product.svg';
import { ReactComponent as Show } from '../assets/icon/show.svg';
import { ReactComponent as Document } from '../assets/icon/document.svg';
import { ReactComponent as Content } from '../assets/icon/content.svg';
import { ReactComponent as Program } from '../assets/icon/program.svg';
import { ReactComponent as Resource } from '../assets/icon/resource.svg';
import { ReactComponent as Event } from '../assets/icon/event.svg';
import { ReactComponent as Micro } from '../assets/icon/micro.svg';
import { ReactComponent as Camera } from '../assets/icon/camera.svg';
import { ReactComponent as Vinyl } from '../assets/icon/vinyl.svg';
import { ReactComponent as Basket } from '../assets/icon/basket.svg';
import { ReactComponent as Athletisme } from '../assets/icon/athletisme.svg';
import { ReactComponent as Football } from '../assets/icon/football.svg';
import { ReactComponent as Gymnastique } from '../assets/icon/gymnastique.svg';
import { ReactComponent as Tennis } from '../assets/icon/tennis.svg';
import { ReactComponent as TennisDeTable } from '../assets/icon/tennisdetable.svg';
import { ReactComponent as VietVoDao } from '../assets/icon/vietvodao.svg';
import { ReactComponent as Cinema } from '../assets/icon/cinema.svg';
import { ReactComponent as Theatre } from '../assets/icon/theatre.svg';
import { ReactComponent as Amicale } from '../assets/icon/amicale.svg';
import { ReactComponent as Association } from '../assets/icon/attendees.svg';
import { ReactComponent as DDProject } from '../assets/icon/ddproject.svg';
import { ReactComponent as DDProduct } from '../assets/icon/ddproduct.svg';
import { ReactComponent as MMEvent } from '../assets/icon/mmevent.svg';
import { ReactComponent as Attendees } from '../assets/icon/attendees.svg';
import { ReactComponent as Star } from '../assets/icon/star.svg';
import { ReactComponent as Analytics } from '../assets/icon/analytics.svg';

function CustomIcon(props) {
  switch (props.icon) {
    case 'news':
      return <News {...props} />;
    case 'user':
      return <User {...props} />;
    case 'product':
      return <Product {...props} />;
    case 'show':
      return <Show {...props} />;
    case 'document':
      return <Document {...props} />;
    case 'content':
      return <Content {...props} />;
    case 'program':
      return <Program {...props} />;
    case 'resource':
      return <Resource {...props} />;
    case 'event':
      return <Event {...props} />;
    case 'vinyl':
      return <Vinyl {...props} />;
    case 'camera':
      return <Camera {...props} />;
    case 'micro':
      return <Micro {...props} />;
    case 'basket':
      return <Basket {...props} />;
    case 'athletisme':
      return <Athletisme {...props} />;
    case 'gymnastique':
      return <Gymnastique {...props} />;
    case 'football':
      return <Football {...props} />;
    case 'tennis':
      return <Tennis {...props} />;
    case 'tennisdetable':
      return <TennisDeTable {...props} />;
    case 'vietvodao':
      return <VietVoDao {...props} />;
    case 'cinema':
      return <Cinema {...props} />;
    case 'theatre':
      return <Theatre {...props} />;
    case 'amicale':
      return <Amicale {...props} />;
    case 'association':
      return <Association {...props} />;
    case 'mmevent':
      return <MMEvent {...props} />;
    case 'attendees':
      return <Attendees {...props} />;
    case 'ddproject':
      return <DDProject {...props} />;
    case 'ddproduct':
      return <DDProduct {...props} />;
    case 'star':
      return <Star {...props} />;
    case 'analytics':
      return <Analytics {...props} />;
    default:
      return null;
  }
}

export default CustomIcon;
