import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import DejaDemain from '../dejademain/Home';

function Router() {
  return (
    <Routes>
      <Route exact path="/dejademain/home" element={<DejaDemain />} />
    </Routes>
  );
}

export default Router;
