import React, { useRef } from 'react';
import moment from 'moment';
import { Card } from 'primereact/card';
import { getImage, getImageFromBackend } from '../../../../utils/imageHelper';
import { SpeedDial } from 'primereact/speeddial';
import './Show.scss';
import { CONSTANT } from '../../../../utils/constants';
import { Button } from 'primereact/button';

function ShowItem({ show, updateShow, deleteShow, manangeImages, previewMode }) {
  const actions = [
    {
      label: 'Modifier',
      icon: 'pi pi-pencil',
      command: () => {
        updateShow();
      }
    },
    {
      label: 'Supprimer',
      icon: 'pi pi-trash',
      command: () => {
        deleteShow();
      }
    }
  ];

  const header = (
    <div className="p-3 pb-0">
      <img
        alt="Card"
        className="h-[180px] object-cover rounded"
        src={(previewMode ? show.previewUrl : show.image?.file) || getImage('img-ph.png')}
      />
    </div>
  );

  const title = <div className="text-xl font-semibold text-slate-900">{show.title}</div>;

  const subtitle = (
    <div className="flex flex-col gap-y-2">
      {show.properties.map((p) => (
        <div key={p.id} className="flex flex-row gap-x-2 items-center">
          <i
            style={{ fontSize: '1rem' }}
            className={
              CONSTANT.nouvelleparenthese.propertyTypes.find((pt) => pt.type === p.type).icon
            }></i>
          <span>{p.label}</span>
        </div>
      ))}
      {show.length && (
        <div className="flex flex-row gap-x-2 items-center">
          <i style={{ fontSize: '1rem' }} className={'pi pi-stopwatch'}></i>
          <span>{show.length}</span>
        </div>
      )}
    </div>
  );

  return (
    <Card
      title={title}
      subTitle={subtitle}
      header={header}
      className={`w-[400px] max-h-[600px] relative`}>
      <p className="m-0 mb-14 line-clamp-3 whitespace-pre-wrap">{show.subtitle}</p>
      {!previewMode && (
        <>
          <Button
            className="h-9 absolute bottom-5 left-5"
            label={`${show.images.length} image${show.images.length > 1 ? 's' : ''}`}
            severity="secondary"
            rounded
            outlined
            onClick={manangeImages}
          />
          <SpeedDial
            key="show-deletion"
            className="absolute bottom-5 right-3 text-xs "
            buttonClassName={'!h-9 !w-9'}
            model={actions}
            direction="up"
            rotateAnimation={false}
            showIcon="pi pi-ellipsis-v"
            hideIcon="pi pi-times"
          />
        </>
      )}
    </Card>
  );
}

export default ShowItem;
