import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import FormInput from '../../../../components/Form/FormInput';
import { InputText } from 'primereact/inputtext';
import RichTextEditor from '../../../../components/Form/RichTextEditor';
import { Button } from 'primereact/button';
import { CONSTANT } from '../../../../utils/constants';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';

function ProductPanel({ isOpen, onClose, product, loading, onUpdate, onCreate }) {
  const [title, setTitle] = useState('');
  const [technicalSpecifications, setTechnicalSpecifications] = useState('');
  const [availableQuantities, setAvailableQuantities] = useState('');
  const [dimensions, setDimensions] = useState('');
  const [weight, setWeight] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (product) {
      setTitle(product.title);
      setTechnicalSpecifications(product.technical_specifications);
      setAvailableQuantities(product.available_quantities);
      setDimensions(product.dimensions);
      setWeight(product.weight);
      setUnitPrice(product.unit_price);
      setType(product.type);
      setDescription(product.description);
    } else {
      setTitle('');
      setTechnicalSpecifications('');
      setAvailableQuantities('');
      setDimensions('');
      setWeight('');
      setUnitPrice('');
      setType(CONSTANT.dejademain.type[0].value);
      setDescription('');
    }
  }, [product, isOpen]);

  const handleValidate = () => {
    const productPayload = {
      title,
      technical_specifications: technicalSpecifications,
      available_quantities: availableQuantities,
      dimensions,
      weight,
      unit_price: unitPrice,
      type,
      description
    };
    product ? onUpdate(productPayload) : onCreate(productPayload);
  };

  const formIsValid = () => {
    return description?.length > 15 && title.length && type && availableQuantities && unitPrice;
  };

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Valider la ${product ? 'modification' : 'création'}`}
        className="w-full"
        onClick={handleValidate}
        severity="success"
        disabled={!formIsValid()}
        loading={loading}
      />
    </div>
  );

  const dropdownTemplate = (option, props) => {
    if (option) {
      return <p>{option.label}</p>;
    }
    return <span>{props.placeholder}</span>;
  };

  return (
    <Sidebar
      style={{ width: 900 }}
      visible={isOpen}
      position="right"
      onHide={() => onClose(false)}
      header={
        <h3 className="text-slate-800 text-2xl font-medium">{`${product ? 'Modifier' : 'Ajouter'} un produit`}</h3>
      }>
      <div className="sidebar-content-container">
        <div className="form-wrapper !pb-60">
          <FormInput label={'Titre du produit'} required isValid={title.length}>
            <InputText
              id="title"
              value={title}
              maxLength={300}
              placeholder="Veuillez saisir le titre du produit"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormInput>
          <FormInput label={'Type de ressource'} required isValid={type}>
            <Dropdown
              id="type"
              value={type}
              onChange={(e) => setType(e.value)}
              options={CONSTANT.dejademain.type}
              optionLabel="label"
              placeholder="Veuillez sélectionner le type de produit"
              valueTemplate={dropdownTemplate}
              itemTemplate={dropdownTemplate}
            />
          </FormInput>
          <FormInput label={'Spécifications techniques'}>
            <InputText
              id="technical_specifications"
              value={technicalSpecifications}
              maxLength={300}
              placeholder="Veuillez saisir ls spécifications techniques"
              onChange={(e) => setTechnicalSpecifications(e.target.value)}
            />
          </FormInput>
          <FormInput label={'Quantités disponibles'} required isValid={availableQuantities}>
            <InputNumber
              id="available_quantities"
              value={availableQuantities}
              onValueChange={(e) => setAvailableQuantities(e.value)}
              placeholder="Veuillez saisir les quantités disponibles"
              showButtons
            />
          </FormInput>
          <FormInput label={'Poids du produit'}>
            <InputNumber
              id="weight"
              value={weight}
              onValueChange={(e) => setWeight(e.value)}
              placeholder="Veuillez saisir le poids du produit"
              showButtons
              min={0}
              suffix={' kg'}
              locale="fr-FR"
              maxFractionDigits={2}
            />
          </FormInput>
          <FormInput label={'Dimensions du produit'}>
            <InputText
              id="dimensions"
              value={dimensions}
              maxLength={300}
              placeholder="Veuillez saisir les dimensions du produit"
              onChange={(e) => setDimensions(e.target.value)}
            />
          </FormInput>
          <FormInput label={'Prix unitaire'} required isValid={unitPrice}>
            <InputNumber
              id="unit_price"
              value={unitPrice}
              onValueChange={(e) => setUnitPrice(e.value)}
              placeholder="Veuillez saisir le prix du produit"
              showButtons
              min={0}
              suffix={' €'}
              locale="fr-FR"
              maxFractionDigits={2}
            />
          </FormInput>

          <FormInput label={'Description'} required isValid={description?.length > 15}>
            <RichTextEditor
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ProductPanel;
