import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import FormInput from '../../../../components/Form/FormInput';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { CONSTANT } from '../../../../utils/constants';
import { Checkbox } from 'primereact/checkbox';
import RichTextEditor from '../../../../components/Form/RichTextEditor';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FileUpload } from 'primereact/fileupload';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { useToastContext } from '../../../../contexts/ToastContext';
import slugify from 'react-slugify';
import { privateFetch } from '../../../../utils/apiHelper';
import { InputNumber } from 'primereact/inputnumber';

function EventManager({ isOpen, onClose, event, onUpdate, loading, onCreate, propertyList }) {
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [placeName, setPlaceName] = useState('');
  const [organizer, setOrganizer] = useState('');
  const [description, setDescription] = useState('');
  const [maxAttendeesNb, setMaxAttendeesNb] = useState(null);
  const [properties, setProperties] = useState([]);
  const [image, setImage] = useState(undefined);
  const [imageLoading, setImageLoading] = useState(false);

  const { accountContext } = useAccountContext();
  const fileUploadRef = useRef(null);
  const { showToast } = useToastContext();

  useEffect(() => {
    if (event) {
      setTitle(event.title);
      setStartDate(new Date(event.start_dt));
      setEndDate(new Date(event.end_dt));
      setPlaceName(event.place_name);
      setOrganizer(event.organizer);
      setDescription(event.description);
      setMaxAttendeesNb(event.max_attendee_nb);
      setImage(event.image);
      setProperties(event.properties);
    } else {
      setTitle('');
      setStartDate(undefined);
      setEndDate(undefined);
      setPlaceName('');
      setOrganizer('');
      setDescription('');
      setMaxAttendeesNb(null);
      setImage(undefined);
      setProperties([]);
    }
  }, [event, isOpen]);

  const onPropertyChange = (e) => {
    let _properties = [...properties];

    if (e.checked) _properties.push(e.value);
    else _properties = _properties.filter((p) => p.id !== e.value.id);

    setProperties(_properties);
  };

  const handleValidate = () => {
    const eventPayload = {
      title,
      start_dt: startDate,
      end_dt: endDate,
      place_name: placeName,
      organizer,
      description,
      max_attendee_nb: maxAttendeesNb,
      image: image?.id,
      properties: properties.map((p) => p.id)
    };
    event ? onUpdate(eventPayload) : onCreate(eventPayload);
  };

  const formIsValid = () => {
    return title.length > 3 && startDate && endDate && placeName.length > 3 && organizer.length > 3;
  };

  const uploadImage = async (imageForm) => {
    await privateFetch(
      'POST',
      'resources',
      `/cu/${accountContext.id}/image/`,
      "L'image a bien été ajouté",
      imageForm,
      false,
      true
    ).then((res) => {
      setImage(res);
    });
    setImageLoading(false);
  };

  const onUpload = (event) => {
    if (event.files[0].size > CONSTANT.maxImageSize) {
      showToast('warn', '10Mo max.');
      onClear();
    } else {
      setImageLoading(true);
      const file = event.files[0];
      const imageForm = new FormData();
      imageForm.append('name', slugify(file.name));
      imageForm.append('file', file);
      uploadImage(imageForm);
    }
  };

  const onClear = () => {
    setImage(undefined);
    fileUploadRef.current.clear();
  };

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Valider la ${event ? 'modification' : 'création'}`}
        className="w-full"
        onClick={handleValidate}
        severity="success"
        disabled={!formIsValid()}
        loading={loading}
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 900 }}
      visible={isOpen}
      position="right"
      onHide={() => onClose(false)}
      header={
        <h3 className="text-slate-800 text-2xl font-medium">{`${event ? 'Modifier' : 'Ajouter'} un Évènement`}</h3>
      }>
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput key="title" label={"Titre de l'évènement"} required isValid={title.length > 3}>
            <InputText
              id="title"
              value={title}
              maxLength={300}
              placeholder="Veuillez saisir le titre de l'évènement"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormInput>
          <FormInput key="start_dt" label={'Date de début'} required isValid={startDate}>
            <Calendar
              id="start_dt"
              onChange={(e) => setStartDate(e.value)}
              placeholder="Veuillez selectionner la date de début"
              value={startDate}
              locale="fr"
              showTime
              hourFormat="24"
              stepMinute={10}
            />
          </FormInput>
          <FormInput key="end_dt" label={'Date de fin'} required isValid={endDate}>
            <Calendar
              id="end_dt"
              onChange={(e) => setEndDate(e.value)}
              placeholder="Veuillez selectionner la date de fin"
              value={endDate}
              locale="fr"
              showTime
              hourFormat="24"
              stepMinute={10}
            />
          </FormInput>

          <FormInput
            key="place_name"
            label={"Lieu de l'évènement"}
            required
            isValid={placeName.length > 3}>
            <InputText
              id="place_name"
              value={placeName}
              maxLength={300}
              placeholder="Veuillez saisir le lieu de l'évènement"
              onChange={(e) => setPlaceName(e.target.value)}
            />
          </FormInput>
          <FormInput
            key="organizer"
            label={"Organisateur de l'évènement"}
            required
            isValid={organizer.length > 3}>
            <InputText
              id="organizer"
              value={organizer}
              maxLength={300}
              placeholder="Veuillez saisir l'organisateur de l'évènement"
              onChange={(e) => setOrganizer(e.target.value)}
            />
          </FormInput>
          <FormInput key="max_attendee_nb" label={'Nombre de participant maximum'}>
            <InputNumber
              id="max_attendee_nb"
              value={maxAttendeesNb}
              onChange={(e) => setMaxAttendeesNb(e.value)}
              mode="decimal"
              showButtons
            />
          </FormInput>

          <FormInput key="properties" label={'Propriétés'}>
            <div className="flex flex-col gap-y-3 ml-2">
              {propertyList.map((p, i) => (
                <div key={i} className="flex flex-row items-center">
                  <Checkbox
                    inputId={p.id}
                    name="property"
                    value={p}
                    onChange={onPropertyChange}
                    checked={properties.some((item) => item.id === p.id)}
                  />
                  <label htmlFor={p.id} className="ml-2 cursor-pointer">
                    {p.label}
                  </label>
                </div>
              ))}
            </div>
          </FormInput>
          <FormInput key="description" label={'Description'}>
            <RichTextEditor
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
              height={'h-48'}
            />
          </FormInput>
          <FormInput
            key={'file'}
            helpMessage={'10Mo max.'}
            label={`Photo principale (max. 10Mo)`}
            required
            isValid={image}>
            {image && (
              <img
                src={image.file}
                className="w-full object-cover"
                alt="Image principale de l'évènement"
              />
            )}
            <div className="flex flex-row gap-x-3">
              {image && (
                <Button
                  key={'delete-img'}
                  label={"Supprimer l'image"}
                  outlined
                  onClick={onClear}
                  severity="danger"
                />
              )}
              {imageLoading ? (
                <div className="w-[50px] h-[50px] p-1">
                  <ProgressSpinner style={{ width: '42px', height: '42px' }} />
                </div>
              ) : (
                <FileUpload
                  ref={fileUploadRef}
                  auto
                  disabled={image}
                  mode="basic"
                  name="file"
                  accept="image/*"
                  customUpload
                  uploadHandler={onUpload}
                  chooseLabel="Depuis mon ordinateur"
                />
              )}
            </div>
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default EventManager;
