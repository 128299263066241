import React from 'react';
import CustomIcon from '../../../../components/CustomIcon';

function NavbarItem({ icon, label, isActive, handleNavigation }) {
  return (
    <div
      onClick={handleNavigation}
      className={`flex flex-row items-center py-2  ${
        isActive
          ? 'bg-slate-950 border-r-indigo-400 border-r-4 opacity-100'
          : 'opacity-80 hover:opacity-100 hover:bg-slate-900 hover:cursor-pointer'
      }`}>
      <div className="mx-5 p-1 h-10 w-10">
        <CustomIcon icon={icon} fill={'#fff'} className={'h-full w-full text-white'} />
      </div>
      <span className={`text-white text-lg font-medium`}>{label}</span>
    </div>
  );
}

export default NavbarItem;
