import React from 'react';
import { formatDate } from '../../../../utils/dateHelper';
import { Button } from 'primereact/button';

function DocumentItem({ document, onUpdate, onDelete }) {
  const handleDelete = (e) => {
    console.log(e);
    e.stopPropagation();
    onDelete();
  };

  return (
    <div
      className="flex flex-row justify-between gap-x-5 items-center rounded border-2 bg-white min-w-64 py-2 px-3 hover:shadow cursor-pointer"
      onClick={onUpdate}>
      <div className="flex flex-row gap-x-3 items-center">
        <i className={`text-3xl opacity-60 pi pi-file-pdf`} />
        <div className="flex flex-col">
          <p className="font-medium text-sm text-gray-800">{document.label}</p>
          <p className="text-sm text-gray-600 italic">{formatDate(document.last_update)}</p>
        </div>
      </div>

      <Button
        rounded
        icon="pi pi-trash"
        severity="danger"
        onClick={handleDelete}
        outlined
        size="small"
        className="!h-9 !w-9"
      />
    </div>
  );
}

export default DocumentItem;
