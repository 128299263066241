import React, { useState, useEffect } from 'react';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { confirmDialog } from 'primereact/confirmdialog';
import ImagePanel from '../../common/image/ImagePanel';
import ProductItem from './ProductItem';
import { useToastContext } from '../../../../contexts/ToastContext';

function ProductPage({ isActive }) {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [productList, setProductlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const { accountContext } = useAccountContext();
  const { showToast } = useToastContext();

  useEffect(() => {
    fetchProductList();
  }, []);

  const fetchProductList = async () => {
    await privateFetch('GET', 'caeterra', `/cu/${accountContext.id}/product/`).then((res) => {
      if (res) setProductlist(res);
    });
  };

  const handleAddImage = (product) => {
    setSelectedProduct(product);
    setPanelIsOpen(true);
  };

  const handleUpdateImage = (image) => {
    setSelectedImage(image);
    setPanelIsOpen(true);
  };

  const uploadProductImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'caeterra',
      `/cu/${accountContext.id}/product/${selectedProduct.id}/image/`,
      "L'image a bien été enregistrée",
      imageForm,
      false,
      true
    ).then(() => {
      fetchProductList();
      setLoading(false);
      setPanelIsOpen(false);
    });
  };

  const updateImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/image/${selectedImage.id}/`,
      "L'image a bien été modifiée",
      imageForm,
      false,
      true
    ).then(() => {
      fetchProductList();
      setLoading(false);
      setPanelIsOpen(false);
    });
  };

  const deleteImage = async (image) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/image/${image.id}/`,
      "L'image a bien été supprimée"
    ).then(() => {
      fetchProductList();
    });
  };

  const confirmDeletion = (image) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cette image ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => deleteImage(image)
    });
  };

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      {/* Header */}
      <h1 className={'text-3xl font-medium'}>{'Produits'}</h1>
      <div className="m-5 flex flex-col gap-y-5 overflow-y-scroll">
        {productList.map((product) => (
          <ProductItem
            key={product.slug}
            product={product}
            deleteImage={(image) => confirmDeletion(image)}
            updateImage={(image) => handleUpdateImage(image)}
            addImage={() => handleAddImage(product)}
          />
        ))}
      </div>

      <ImagePanel
        image={selectedImage}
        loading={loading}
        isOpen={panelIsOpen}
        onClose={() => {
          setSelectedProduct(null);
          setSelectedImage(null);
          setPanelIsOpen(false);
        }}
        onCreate={uploadProductImage}
        onUpdate={updateImage}
      />
    </div>
  );
}

export default ProductPage;
