import React, { useEffect, useState } from 'react';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import PageWrapper from '../../../../components/Display/PageWrapper';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { DataView } from 'primereact/dataview';
import ProjectItem from '../Project/ProjectItem';
import ProjectDetail from '../Project/ProjectDetail';
import ProjectPanel from './ProjectPanel';
import ImagePanel from '../../common/image/ImagePanel';

function ProjectPage({ isActive }) {
  const { openProjectDetail, navigationContext } = useNavigationContext();
  const { accountContext } = useAccountContext();
  const [projectList, setProjectList] = useState([]);
  const [projectDetail, setProjectDetail] = useState(undefined);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [imageManagerIsOpen, setImageManagerIsOpen] = useState(false);

  const fetchProjectList = async () => {
    await privateFetch('GET', 'dejademain', `/cu/${accountContext.id}/project/`).then((res) => {
      setProjectList(res);
    });
  };

  useEffect(() => {
    fetchProjectList();
  }, []);

  useEffect(() => {
    if (navigationContext.acitveProjectId) {
      fetchProjectDetail();
    } else setProjectDetail(undefined);
  }, [navigationContext]);

  const fetchProjectDetail = async () => {
    await privateFetch(
      'GET',
      'dejademain',
      `/cu/${accountContext.id}/project/${navigationContext.acitveProjectId}/`
    ).then((res) => {
      if (res) setProjectDetail(res);
    });
  };

  const handleClickProject = (project) => {
    openProjectDetail(project);
  };

  const handleOpenManager = () => {
    setPanelIsOpen(true);
  };

  const createProject = async (projectForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'dejademain',
      `/cu/${accountContext.id}/project/`,
      'Le projet a bien été ajouté',
      JSON.stringify(projectForm)
    ).then(() => {
      fetchProjectList();
      setPanelIsOpen(false);
      setLoading(false);
    });
  };

  const updateProject = async (projectForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'dejademain',
      `/cu/${accountContext.id}/project/${projectDetail.id}/`,
      'Le projet a bien été modifié',
      JSON.stringify(projectForm)
    ).then(() => {
      fetchProjectList();
      setPanelIsOpen(false);
      fetchProjectDetail(projectDetail.id);
      setLoading(false);
    });
  };

  const handleDeleteProject = async (project) => {
    await privateFetch(
      'DELETE',
      'dejademain',
      `/cu/${accountContext.id}/project/${project.id}/`,
      'Le projet a bien été supprimé'
    ).then(() => {
      fetchProjectList();
      setProjectDetail(undefined);
    });
  };

  const newProjectAction = {
    onClick: handleOpenManager,
    label: 'Ajouter un projet',
    icon: 'pi-plus'
  };

  const goBackProjectAction = {
    onClick: handleClickProject,
    label: 'Retour à la liste'
  };

  const handleCreateImage = () => {
    setSelectedImage(undefined);
    setImageManagerIsOpen(true);
  };

  const handleUpdateImage = (image) => {
    setSelectedImage(image);
    setImageManagerIsOpen(true);
  };

  const handleDeleteImage = async (image) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/image/${image.id}/`,
      "L'image a bien été supprimée"
    ).then(() => {
      fetchProjectDetail();
      fetchProjectList();

      setSelectedImage(undefined);
    });
  };

  const uploadResourceImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'dejademain',
      `/cu/${accountContext.id}/project/${projectDetail.id}/image/`,
      "L'image a bien été enregistrée",
      imageForm,
      false,
      true
    ).then(() => {
      fetchProjectDetail();
      fetchProjectList();

      setLoading(false);
      setImageManagerIsOpen(false);
    });
  };

  const updateImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/image/${selectedImage.id}/`,
      "L'image a bien été modifiée",
      imageForm,
      false,
      true
    ).then(() => {
      fetchProjectDetail();
      fetchProjectList();
      setLoading(false);
      setImageManagerIsOpen(false);
    });
  };

  const itemTemplate = (project) => (
    <ProjectItem project={project} handleClickProject={() => handleClickProject(project)} />
  );

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      <PageWrapper
        label="Projets"
        labelAction={handleClickProject}
        action={projectDetail ? goBackProjectAction : newProjectAction}
        detail={projectDetail}>
        {!projectDetail ? (
          <DataView value={projectList} itemTemplate={itemTemplate} className="pb-20" />
        ) : (
          <ProjectDetail
            project={projectDetail}
            handleUpdateProject={handleOpenManager}
            handleCreateImage={handleCreateImage}
            handleUpdateImage={handleUpdateImage}
            handleDeleteImage={handleDeleteImage}
            handleDelete={handleDeleteProject}
          />
        )}

        <ProjectPanel
          isOpen={panelIsOpen}
          onClose={setPanelIsOpen}
          project={projectDetail}
          onCreate={createProject}
          onUpdate={updateProject}
          loading={loading}
        />
        <ImagePanel
          image={selectedImage}
          loading={loading}
          isOpen={imageManagerIsOpen}
          onClose={() => {
            setSelectedImage(null);
            setImageManagerIsOpen(false);
          }}
          onCreate={uploadResourceImage}
          onUpdate={updateImage}
        />
      </PageWrapper>
    </div>
  );
}

export default ProjectPage;
