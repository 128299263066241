import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import Home from './Home';

function Router() {
  return (
    <Routes>
      <Route exact path="/nouvelleparenthese/home" element={<Home />} />
    </Routes>
  );
}

export default Router;
