import React, { createContext, useEffect, useContext, useRef } from 'react';
import { Toast } from 'primereact/toast';

const ToastContext = createContext(null);

export const ToastContextProvider = ({ children }) => {
  const toast = useRef(null);

  useEffect(() => {
    window.PrimeToast = toast.current || {};
  }, []);

  const showToast = (severity, title) => {
    toast.current.show({ severity: severity, summary: title });
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      <Toast ref={toast} position="bottom-center" />
      {children}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  return useContext(ToastContext);
};
