import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { publicFetch } from '../../../utils/apiHelper';
import FormInputSignin from '../../../components/Form/FormInputSignin';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { useNavigate, useParams } from 'react-router-dom';
import { Message } from 'primereact/message';
import Layout from '../Layout';

function PasswordSetupForm() {
  const [password, setPassword] = useState('');
  const [passwordBis, setPasswordBis] = useState('');
  const [loading, setLoading] = useState(false);

  const [account, setAccount] = useState(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      fetchAccountDetail(token);
    } else {
      console.log('Error: token not found');
    }
  }, []);

  const fetchAccountDetail = async (token) => {
    await publicFetch('GET', 'user', `/user/${token}/`).then((res) => {
      setAccount(res);
    });
  };

  const handlePasswordSetup = async () => {
    setLoading(true);
    await publicFetch(
      'PUT',
      'user',
      `/user/${token}/`,
      'Une erreur est survenue. Veuillez réessayer.',
      JSON.stringify({
        password: password
      })
    ).then((response) => {
      if (!response) {
        setLoading(false);
      } else {
        setDialogOpen(true);
        setTimeout(() => {
          navigate('/');
        }, 5000);
      }
    });
  };

  const passwordIsValid = () => {
    return password.length > 7;
  };

  const passwordBisIsValid = () => {
    return passwordBis.length > 7 && passwordBis === password;
  };

  return (
    <Layout>
      <div className="flex flex-col gap-y-16 ml-36 w-[500px] mb-8">
        <div className="flex flex-col gap-3">
          <span className="text-5xl font-semibold">Bienvenue</span>
          <span className="text-2xl font-normal">Choisir votre nouveau mot de passe</span>
        </div>
        {account && (
          <div className="flex flex-col gap-6">
            <FormInputSignin label={'Adresse mail'} required isValid={true}>
              <InputText
                disabled
                readOnly
                id="email"
                type="text"
                placeholder="Entrez votre adresse email"
                className="w-full"
                value={account?.email}
              />
            </FormInputSignin>

            <FormInputSignin label={'Mot de passe'} required isValid={passwordIsValid()}>
              <InputText
                id="password"
                type="password"
                placeholder="Entrez votre mot de passe"
                className="w-full"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormInputSignin>

            <FormInputSignin
              label={'Répéter le mot de passe'}
              required
              isValid={passwordBisIsValid()}>
              <InputText
                id="password"
                type="password"
                placeholder="Entrez votre mot de passe"
                className="w-full"
                value={passwordBis}
                onChange={(e) => setPasswordBis(e.target.value)}
              />
            </FormInputSignin>
          </div>
        )}

        {account ? (
          <Button
            label="Valider mon mot de passe"
            icon="pi pi-lock"
            className="btn"
            loading={loading}
            disabled={!account || !passwordIsValid() || !passwordBisIsValid()}
            onClick={handlePasswordSetup}
          />
        ) : (
          <Message severity="error" text={`Le lien est erroné.`} />
        )}

        <Dialog
          header="Configuration terminée"
          visible={dialogOpen}
          style={{ width: '30vw' }}
          draggable={false}
          closable={false}>
          <div className="flex flex-row gap-x-5 items-center mb-5">
            <div style={{ width: 30, marginRight: 15 }}>
              <ProgressSpinner style={{ width: '30px', height: '30px' }} />
            </div>
            <p>Vous allez être redirigé vers la page de connexion...</p>
          </div>
        </Dialog>
      </div>
    </Layout>
  );
}

export default PasswordSetupForm;
