import React, { useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import FormInput from '../../../../components/Form/FormInput';
import RichTextEditor from '../../../../components/Form/RichTextEditor';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

function SectionPanel({ isOpen, onClose, section, onUpdate, loading }) {
  const [description, setDescription] = useState('');
  const [registrationMethod, setRegistrationMethod] = useState('');
  const [registrationDate, setRegistrationDate] = useState('');
  const [timetable, setTimetable] = useState('');
  const [pricing, setPricing] = useState('');
  const [additionnalInformation, setAdditionnalInformation] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');

  const hasText = (content) => {
    var regex = /(<([^>]+)>)/gi;
    return !!content.replace(regex, '').length;
  };

  useEffect(() => {
    setDescription(section.description);
    setRegistrationMethod(section.registration_method);
    setRegistrationDate(section.registration_date);
    setTimetable(section.timetable);
    setPricing(section.pricing);
    setAdditionnalInformation(section.additionnal_information);
    setFacebookUrl(section.facebook_url);
    setInstagramUrl(section.instagram_url);
    setWebsiteUrl(section.websiteUrl);
  }, [section, isOpen]);

  const handleValidate = () => {
    const sectionPayload = {
      title: section.title,
      description: hasText(description) ? description : '',
      registration_method: hasText(registrationMethod) ? registrationMethod : '',
      registration_date: hasText(registrationDate) ? registrationDate : '',
      timetable: hasText(timetable) ? timetable : '',
      pricing: hasText(pricing) ? pricing : '',
      additionnal_information: hasText(additionnalInformation) ? additionnalInformation : '',
      facebook_url: facebookUrl,
      instagram_url: instagramUrl,
      website_url: websiteUrl
    };
    onUpdate(sectionPayload);
  };

  const formIsValid = () => {
    return description?.length > 15;
  };

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={'Valider la modification'}
        className="w-full"
        onClick={handleValidate}
        severity="success"
        disabled={!formIsValid()}
        loading={loading}
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 900 }}
      visible={isOpen}
      position="right"
      onHide={() => onClose(false)}
      header={<h3 className="text-slate-800 text-2xl font-medium ">Modifier la section</h3>}>
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={'Description'} required isValid={description?.length > 15}>
            <RichTextEditor
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
              height={'h-48'}
            />
          </FormInput>
          <FormInput label={"Modalités d'inscriptions"}>
            <RichTextEditor
              value={registrationMethod}
              onChange={(value) => {
                setRegistrationMethod(value);
              }}
              height={'h-48'}
            />
          </FormInput>
          <FormInput label={"Dates d'inscriptions"}>
            <RichTextEditor
              value={registrationDate}
              onChange={(value) => {
                setRegistrationDate(value);
              }}
              height={'h-48'}
            />
          </FormInput>
          <FormInput label={'Tarifs'}>
            <RichTextEditor
              value={pricing}
              onChange={(value) => {
                setPricing(value);
              }}
              height={'h-48'}
            />
          </FormInput>

          <FormInput label={'Horaires'}>
            <RichTextEditor
              value={timetable}
              onChange={(value) => {
                setTimetable(value);
              }}
              height={'h-48'}
            />
          </FormInput>
          <FormInput label={'Informations Supplémentaires'}>
            <RichTextEditor
              value={additionnalInformation}
              onChange={(value) => {
                setAdditionnalInformation(value);
              }}
              height={'h-48'}
            />
          </FormInput>
          <FormInput label={'URL page Facebook'}>
            <InputText
              id="facebookUrl"
              value={facebookUrl}
              maxLength={300}
              placeholder="Veuillez saisir l'url de la page Facebook"
              onChange={(e) => setFacebookUrl(e.target.value)}
            />
          </FormInput>
          <FormInput label={'URL page Instagram'}>
            <InputText
              id="instagramUrl"
              value={instagramUrl}
              maxLength={300}
              placeholder="Veuillez saisir l'url de la page Instagram"
              onChange={(e) => setInstagramUrl(e.target.value)}
            />
          </FormInput>
          <FormInput label={'URL page Web'}>
            <InputText
              id="websiteUrl"
              value={websiteUrl}
              maxLength={300}
              placeholder="Veuillez saisir l'url de votre site internet"
              onChange={(e) => setWebsiteUrl(e.target.value)}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default SectionPanel;
