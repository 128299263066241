import React, { useMemo } from 'react';
import Data from '../../../../components/Display/Data';
import moment from 'moment';
import { Button } from 'primereact/button';
import 'moment/locale/fr';
import { capitalizeFirstLetter } from '../../../../utils/stringHelper';
import DataTag from '../../../../components/Display/DataTag';
import MultipleDataTag from '../../../../components/Display/MultipleDataTag';

function EventListItem({ event, duplicateEvent, deleteEvent, updateEvent }) {
  const isUpcoming = useMemo(() => moment(event.start_dt).isAfter(moment()), []);
  return (
    <div className="flex flex-row w-full justify-between p-5 rounded-lg shadow mb-5">
      <div className={`w-full mb-4 flex flex-col gap-y-3`}>
        <span className="text-lg font-semibold text-slate-700">{event.place_name}</span>
        <div className="flex-row flex flex-wrap gap-3">
          <Data
            key="start-dt"
            label={'Date de début'}
            value={capitalizeFirstLetter(moment(event.start_dt).format('LLLL'))}
            large
          />
          <Data key="website_url" label={'Site web du lieu'} value={event.website_url} large />
          <Data
            key="booking_url"
            label={'Lien de réservation des tickets'}
            value={event.booking_url}
            large
          />
        </div>
        <div className="flex-row flex flex-wrap gap-3">
          <DataTag
            key="state"
            label={'État'}
            tag={{
              label: isUpcoming ? 'A venir' : 'Passé',
              bgColor: isUpcoming ? 'bg-green-500' : 'bg-slate-500'
            }}
            large
          />
          <MultipleDataTag key="properties" label={'Propriétés'} tags={event.properties} />
        </div>
      </div>

      <div className="flex gap-2">
        <Button
          rounded
          icon="pi pi-pencil"
          severity="success"
          onClick={() => updateEvent(event)}
          outlined
          size="small"
          className="!h-9 !w-9"
        />
        <Button
          rounded
          icon="pi pi-clone"
          severity="info"
          onClick={() => {
            duplicateEvent(event);
          }}
          outlined
          size="small"
          className="!h-9 !w-9"
        />
        <Button
          rounded
          icon="pi pi-trash"
          severity="danger"
          onClick={() => deleteEvent(event)}
          outlined
          size="small"
          className="!h-9 !w-9"
        />
      </div>
    </div>
  );
}

export default EventListItem;
