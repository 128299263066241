import React, { useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { AutoComplete } from 'primereact/autocomplete';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import FormInput from '../../../../components/Form/FormInput';
import { Button } from 'primereact/button';
import Data from '../../../../components/Display/Data';
import _ from 'underscore';
import slugify from 'react-slugify';
import { OrderList } from 'primereact/orderlist';

function DistributionManager({
  isOpen,
  onClose,
  createDistribution,
  deleteDistribution,
  handleOrderChange,
  distributions
}) {
  const [distributionList, setDistributionList] = useState([]);
  const { accountContext } = useAccountContext();
  const [filteredDistribList, setFilteredDistribList] = useState([]);
  const [role, setRole] = useState('');
  const [alias, setAlias] = useState('');

  const fetchDistributionList = async () => {
    await privateFetch('GET', 'banquetceleste', `/cu/${accountContext.id}/distribution/`).then(
      (res) => {
        setDistributionList(res);
      }
    );
  };

  useEffect(() => {
    fetchDistributionList();
  }, [distributions]);

  const searchAlias = (event) => {
    setFilteredDistribList(
      distributionList.filter((distribution) =>
        slugify(distribution.alias).includes(slugify(event.query))
      )
    );
  };

  const searchRole = (event) => {
    setFilteredDistribList(
      distributionList.filter((distribution) =>
        slugify(distribution.role).includes(slugify(event.query))
      )
    );
  };

  const handleValidate = () => {
    const distribPayload = {
      alias: alias,
      role: role
    };
    setAlias('');
    setRole('');
    createDistribution(distribPayload);
  };

  const formIsValid = () => {
    return role && alias;
  };

  const itemTemplate = (d) => (
    <div className="flex flex-row items-center gap-x-3 w-full">
      <Button
        key={`delete-${d.id}`}
        className="!w-9 !h-9 text-red-700 bg-white border-0 border-red-700 hover:bg-red-100 hover:border"
        size="small"
        onClick={() => deleteDistribution(d)}
        icon="pi pi-trash"
        rounded
      />
      <div className="flex flex-col">
        <p className="text-sm text-gray">{d.role}</p>
        <p className="font-semibold">{d.alias}</p>
      </div>
    </div>
  );

  return (
    <Sidebar
      style={{ width: 600 }}
      visible={isOpen}
      position="right"
      onHide={() => onClose(false)}
      header={
        <h3 className="text-slate-800 text-2xl font-medium">{`Modifier la distribution`}</h3>
      }>
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <div className="flex flex-col gap-y-3 mb-5">
            <h4 className="uppercase text-sm font-semibold text-slate-600">
              Ajouter une nouvelle entrée
            </h4>
            <div className="flex flex-row ">
              <div className="flex flex-col gap-3 pr-3 w-11/12">
                <FormInput label={'Nom & Prénom'} required isValid={alias}>
                  <AutoComplete
                    value={alias}
                    suggestions={_.uniq(
                      filteredDistribList.map((distribution) => distribution.alias)
                    )}
                    completeMethod={searchAlias}
                    onChange={(e) => setAlias(e.value)}
                    dropdown
                    autoHighlight
                    scrollHeight={450}
                    placeholder="Saisir le nom et prénom de l'artiste"
                  />
                </FormInput>
                <FormInput label={'Rôle'} required isValid={role}>
                  <AutoComplete
                    value={role}
                    suggestions={_.uniq(
                      filteredDistribList.map((distribution) => distribution.role)
                    )}
                    completeMethod={searchRole}
                    onChange={(e) => setRole(e.value)}
                    dropdown
                    autoHighlight
                    scrollHeight={450}
                    placeholder="Saisir un rôle"
                  />
                </FormInput>
              </div>
              <div className="flex flex-col justify-end items-end w-1/12">
                <Button
                  key={'validate'}
                  disabled={!formIsValid()}
                  onClick={handleValidate}
                  severity="success"
                  icon="pi pi-check"
                  rounded
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-3 mb-5">
            <h4 className="uppercase text-sm font-semibold text-slate-600">
              Distribution actuelle
            </h4>
            {distributions?.length ? (
              <OrderList
                dataKey="id"
                value={distributions}
                header={null}
                onChange={(e) => handleOrderChange(e.value)}
                itemTemplate={itemTemplate}
                className="flex flex-row-reverse"></OrderList>
            ) : (
              <span className="text-gray-500 italic text-sm">
                Aucune distribution pour le moment
              </span>
            )}
          </div>
        </div>
      </div>
    </Sidebar>
  );
}

export default DistributionManager;
