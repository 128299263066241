import React, { useState, useRef } from 'react';
import FormInput from '../../../../components/Form/FormInput';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { useEffect } from 'react';
import { CONSTANT, getConstant } from '../../../../utils/constants';
import { FileUpload } from 'primereact/fileupload';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import slugify from 'react-slugify';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useToastContext } from '../../../../contexts/ToastContext';
import RichTextEditor from '../../../../components/Form/RichTextEditor';
import { Dropdown } from 'primereact/dropdown';
import Tag from '../../../../components/Display/Tag';
import { Checkbox } from 'primereact/checkbox';

function ProgramPanel({ isOpen, onClose, onCreate, program, onUpdate, loading, propertyList }) {
  const [title, setTitle] = useState('');
  const [type, setType] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [author, setAuthor] = useState('');
  const [deezerId, setDeezerId] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [credits, setCredits] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState(undefined);
  const [resourceIdList, setResourceIdList] = useState([]);
  const [resourceList, setResourceList] = useState([]);
  const [image, setImage] = useState(undefined);
  const [imageLoading, setImageLoading] = useState(false);
  const [properties, setProperties] = useState([]);

  const { accountContext } = useAccountContext();
  const fileUploadRef = useRef(null);
  const { showToast } = useToastContext();

  useEffect(() => {
    if (program) {
      setTitle(program.title);
      setType(program.type);
      setStatus(program.status);
      setAuthor(program.author);
      setDeezerId(program.deezer_id);
      setVideoUrl(program.video_url);
      setCredits(program.credits);
      setDescription(program.description);
      setDate(new Date(program.date));
      setImage(program.image);
      setResourceIdList(program.resources.map((r) => r.id));
      setProperties(program.properties);
    } else {
      setTitle('');
      setType(undefined);
      setStatus(undefined);
      setAuthor('');
      setDeezerId('');
      setVideoUrl('');
      setCredits('');
      setDescription('');
      setDate(new Date());
      setImage(undefined);
      setResourceIdList([]);
      setProperties([]);
    }
  }, [program, isOpen]);

  const fetchResourceList = async () => {
    await privateFetch('GET', 'banquetceleste', `/cu/${accountContext.id}/resource/`).then(
      (res) => {
        setResourceList(res);
      }
    );
  };

  useEffect(() => {
    fetchResourceList();
  }, [isOpen]);

  const uploadImage = async (imageForm) => {
    await privateFetch(
      'POST',
      'resources',
      `/cu/${accountContext.id}/image/`,
      "L'image a bien été ajouté",
      imageForm,
      false,
      true
    ).then((res) => {
      setImage(res);
    });
    setImageLoading(false);
  };

  const onUpload = (event) => {
    if (event.files[0].size > CONSTANT.maxImageSize) {
      showToast('warn', '10Mo max.');
      onClear();
    } else {
      setImageLoading(true);
      const file = event.files[0];
      const imageForm = new FormData();
      imageForm.append('name', slugify(file.name));
      imageForm.append('file', file);
      uploadImage(imageForm);
    }
  };

  const onClear = () => {
    setImage(undefined);
    fileUploadRef.current.clear();
  };

  const handleValidate = () => {
    const programPayload = {
      title,
      type,
      status,
      author,
      deezer_id: deezerId,
      video_url: videoUrl,
      credits,
      description,
      date: moment(date).format('YYYY-MM-DD'),
      image: image?.id,
      resources: resourceIdList,
      properties: properties.map((p) => p.id)
    };
    program ? onUpdate(programPayload) : onCreate(programPayload);
  };

  const onPropertyChange = (e) => {
    let _properties = [...properties];

    if (e.checked) _properties.push(e.value);
    else _properties = _properties.filter((p) => p.id !== e.value.id);

    setProperties(_properties);
  };

  const formIsValid = () => {
    return (
      title.length > 3 && author.length > 3 && description?.length > 3 && date && image && type
    );
  };

  const dropdownTemplate = (option, props) => {
    if (option) {
      return (
        <Tag
          tag={{
            label: option.label,
            bgColor: option.bgColor
          }}
          rounded
        />
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Valider la ${program ? 'modification' : 'création'}`}
        className="w-full"
        onClick={handleValidate}
        severity="success"
        disabled={!formIsValid()}
        loading={loading}
      />
    </div>
  );

  const resourceSelectTemplate = (option) => {
    return (
      <div className="flex flex-row w-full items-center justify-between">
        <span>{option.title}</span>
        <Tag tag={getConstant('banquetceleste.resourceTypes', option.type)} />
      </div>
    );
  };

  return (
    <Sidebar
      style={{ width: 800 }}
      visible={isOpen}
      position="right"
      onHide={() => onClose(false)}
      header={
        <h3 className="text-slate-800 text-2xl font-medium">{`${program ? 'Modifier' : 'Ajouter'} un programme`}</h3>
      }>
      <div className="sidebar-content-container">
        <div className="form-wrapper !pb-60">
          <FormInput key="title" label={'Titre du programme'} required isValid={title.length > 3}>
            <InputText
              id="title"
              value={title}
              maxLength={300}
              placeholder="Veuillez saisir le titre du programme"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormInput>

          <FormInput key="type" label={'Type du programme'} required isValid={type}>
            <Dropdown
              id="type"
              value={type}
              onChange={(e) => setType(e.value)}
              options={CONSTANT.banquetceleste.programTypes}
              optionLabel="label"
              placeholder="Veuillez sélectionner le type de programme"
              valueTemplate={dropdownTemplate}
              itemTemplate={dropdownTemplate}
            />
          </FormInput>
          {program && (
            <FormInput key="status" label={'État du programme'}>
              <Dropdown
                id="status"
                value={status}
                onChange={(e) => setStatus(e.value)}
                options={CONSTANT.banquetceleste.common.statusTypes}
                optionLabel="label"
                placeholder="Veuillez sélectionner l'état de programme"
                valueTemplate={dropdownTemplate}
                itemTemplate={dropdownTemplate}
              />
            </FormInput>
          )}

          <FormInput
            key="author"
            label={'Auteur.e.s du programme'}
            required
            isValid={author.length > 3}>
            <InputText
              id="author"
              value={author}
              maxLength={300}
              placeholder="Veuillez saisir les auteur.e.s du programme"
              onChange={(e) => setAuthor(e.target.value)}
            />
          </FormInput>

          <FormInput key="deezer" label={'ID Deezer'}>
            <InputText
              id="deezerId"
              value={deezerId}
              maxLength={100}
              placeholder="Veuillez saisir l'id unique Deezer"
              onChange={(e) => setDeezerId(e.target.value)}
            />
          </FormInput>

          <FormInput label={'URL de la vidéo'}>
            <InputText
              id="videoUrl"
              value={videoUrl}
              placeholder="Veuillez saisir l'url de la vidéo"
              onChange={(e) => setVideoUrl(e.target.value)}
            />
          </FormInput>

          <FormInput key="property" label={'Propriétés'}>
            <div className="flex flex-col gap-y-3 ml-2">
              {propertyList
                .filter((p) => p.type === 'bc_program')
                .map((p, i) => (
                  <div key={i} className="flex flex-row items-center">
                    <Checkbox
                      inputId={p.id}
                      name="property"
                      value={p}
                      onChange={onPropertyChange}
                      checked={properties.some((item) => item.id === p.id)}
                    />
                    <label htmlFor={p.id} className="ml-2 cursor-pointer">
                      {p.label}
                    </label>
                  </div>
                ))}
            </div>
          </FormInput>

          <FormInput
            key="description"
            label={'Description'}
            helpMessage={'Utiliser ctrl+maj+v pour coller le texte sans formattage'}
            required
            isValid={description?.length > 3}>
            <RichTextEditor
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </FormInput>

          <FormInput
            key="credits"
            label={'Crédits'}
            helpMessage={'Utiliser ctrl+maj+v pour coller le texte sans formattage'}>
            <RichTextEditor
              value={credits}
              onChange={(value) => setCredits(value)}
              height={'h-48'}
            />
          </FormInput>
          <FormInput key="date" label={'Date de publication'} required isValid={date}>
            <Calendar
              id="date"
              onChange={(e) => setDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date de publication"
              maxDate={new Date()}
              value={date}
              locale="fr"
            />
          </FormInput>

          <FormInput key="ressources" label={'Ressources'}>
            <MultiSelect
              value={resourceIdList}
              onChange={(e) => setResourceIdList(e.value)}
              options={resourceList}
              optionLabel="title"
              optionValue="id"
              placeholder="Sélectionnez des ressources"
              itemTemplate={resourceSelectTemplate}
              display="chip"
              filter
              scrollHeight={350}
            />
          </FormInput>
          <FormInput
            key={'file'}
            helpMessage={'10Mo max.'}
            label={`Photo principale (max. 10Mo)`}
            required
            isValid={image}>
            {image && (
              <img
                src={image.file}
                className="w-full object-cover"
                alt="Image principale du programme"
              />
            )}
            <div className="flex flex-row gap-x-3 mt-3">
              {image && (
                <Button
                  key={'delete-img'}
                  label={"Supprimer l'image"}
                  outlined
                  onClick={onClear}
                  severity="danger"
                />
              )}
              {imageLoading ? (
                <div className="w-[50px] h-[50px] p-1">
                  <ProgressSpinner style={{ width: '42px', height: '42px' }} />
                </div>
              ) : (
                <FileUpload
                  ref={fileUploadRef}
                  auto
                  disabled={image}
                  mode="basic"
                  name="file"
                  accept="image/*"
                  customUpload
                  uploadHandler={onUpload}
                  chooseLabel="Depuis mon ordinateur"
                />
              )}
            </div>
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ProgramPanel;
