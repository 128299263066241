import React from 'react';
import Section from '../../../../components/Display/Section';
import Data from '../../../../components/Display/Data';
import { getConstant } from '../../../../utils/constants';
import MultiLineData from '../../../../components/Display/MultiLineData';
import ImageItem from '../../common/image/ImageItem';
import { confirmDialog } from 'primereact/confirmdialog';
import DataTag from '../../../../components/Display/DataTag';
import { Button } from 'primereact/button';

function ProductDetail({
  product,
  handleUpdateProduct,
  handleCreateImage,
  handleUpdateImage,
  handleDeleteImage,
  handleDelete
}) {
  const updateProductActions = [
    {
      onClick: handleUpdateProduct,
      label: 'Modifier les informations',
      icon: 'pi-pencil'
    }
  ];

  const newImageActions = [
    {
      onClick: handleCreateImage,
      label: 'Ajouter une image',
      icon: 'pi-plus'
    }
  ];

  const confirmImageDeletion = (image) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cette image ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteImage(image)
    });
  };

  const confirmDeletion = (product) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer ce Produit ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDelete(product)
    });
  };

  return (
    <div className={`h-full w-full flex-col flex gap-5`}>
      <Section label="Informations Générales" actions={updateProductActions}>
        <div className="flex flex-wrap gap-5">
          <Data key="title" label={'Titre'} value={product.title} large />

          <DataTag
            key="type"
            label={'Type'}
            tag={getConstant('dejademain.type', product.type)}
            large
            rounded
          />
          <Data
            key="technical_specifications"
            label={'Spécifications techniques'}
            value={product.technical_specifications}
            large
          />
          <Data
            key="available_quantities"
            label={'Quantités disponibles'}
            value={product.available_quantities}
            large
          />
          <Data key="dimensions" label={'Dimensions'} value={product.dimensions} large />
          <Data key="weight" label={'Poids'} value={product.weight} large suffix={'kg'} />
          <Data
            key="unit_price"
            label={'Prix unitaire'}
            value={product.unit_price}
            large
            suffix={'€'}
          />
        </div>
        <div className="flex flex-wrap gap-5">
          <MultiLineData
            key="description"
            label={'Description'}
            value={product.description}
            large
            asHtml
          />
        </div>
      </Section>
      <Section label="Images" actions={newImageActions}>
        <div className="flex flex-wrap gap-3">
          {!product.images.length && (
            <span className="italic text-slate-400 font-light">Aucune image à afficher</span>
          )}
          {product.images.map((img, index) => (
            <ImageItem
              key={index}
              image={img}
              alt={`Photo de ${product.title} n°${index}`}
              imageClassName="h-60"
              updateImage={() => handleUpdateImage(img)}
              deleteImage={() => confirmImageDeletion(img)}
            />
          ))}
        </div>
      </Section>
      <Section label="Autres actions">
        <div className="flex flex-row">
          <Button
            key={'delete'}
            label={'Supprimer le produit'}
            className="!w-54"
            outlined
            onClick={() => confirmDeletion(product)}
            severity="danger"
          />
        </div>
      </Section>
    </div>
  );
}

export default ProductDetail;
