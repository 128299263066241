import React from 'react';
import { getImage } from '../../../../utils/imageHelper';
import { getConstant } from '../../../../utils/constants';
import DataTag from '../../../../components/Display/DataTag';

function ProductItem({ product, handleClickProduct }) {
  return (
    <div
      className="flex flex-row items-start py-5 px-3 gap-x-5 cursor-pointer hover:bg-slate-100"
      onClick={handleClickProduct}>
      <img
        className="h-32 w-32 object-cover shadow-md block rounded-md"
        src={product.images[0]?.file ? product.images[0].file : getImage('img-ph.png')}
        alt={product.images[0]?.label ? product.images[0].label : 'Non renseignée'}
      />
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-y-1">
          <div className="text-xl font-bold text-slate-700 ">{product.title}</div>
          <DataTag key="type" tag={getConstant('dejademain.type', product.type)} large rounded />
        </div>
      </div>
    </div>
  );
}

export default ProductItem;
