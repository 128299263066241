import React, { useState, useEffect } from 'react';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import DocumentList from '../../../../components/Document/DocumentList';
import ContentDocumentPanel from './ContentDocumentPanel';

function ContentPage({ isActive }) {
  const { accountContext } = useAccountContext();

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [panelDocumentIsOpen, setPanelDocumentIsOpen] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDocumentList();
  }, []);

  const fetchDocumentList = async () => {
    await privateFetch('GET', 'caeterra', `/cu/${accountContext.id}/document/`).then((res) => {
      if (res) setDocumentList(res);
    });
  };

  const updateDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'caeterra',
      `/cu/${accountContext.id}/document/${selectedDocument.id}/`,
      'Le document a bien été modifié',
      documentForm,
      false,
      true
    ).then(() => {
      fetchDocumentList();
      setLoading(false);
      setPanelDocumentIsOpen(false);
    });
  };

  const handleUpdateDocument = (document) => {
    setSelectedDocument(document);
    setPanelDocumentIsOpen(true);
  };

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      {/* Header */}
      <div className="flex flex-col gap-y-3">
        <h1 className={'text-3xl font-medium'}>{'Contenus'}</h1>
        <h3 className="mt-3">{'Documents téléchargeables'}</h3>
        <DocumentList documents={documentList} onUpdate={handleUpdateDocument} />
      </div>

      <ContentDocumentPanel
        loading={loading}
        document={selectedDocument}
        isOpen={panelDocumentIsOpen}
        handleClose={() => {
          setSelectedDocument(undefined);
          setPanelDocumentIsOpen(false);
        }}
        updateDocument={updateDocument}
      />
    </div>
  );
}

export default ContentPage;
