import React, { useRef } from 'react';
import { getImage, getImageFromBackend } from '../../../../utils/imageHelper';
import { Button } from 'primereact/button';
import ImageItem from '../../common/image/ImageItem';

function ProductItem({ product, addImage, updateImage, deleteImage }) {
  return (
    <div className="flex flex-col gap-y-3 w-full">
      <div className="flex flex-row w-full justify-between items-center">
        <h2 className="text-2xl font-medium text-slate-700">{product.name}</h2>
        <Button label="Nouvelle image" icon={'pi pi-plus'} onClick={addImage} />
      </div>
      <div className="flex flex-wrap gap-3 py-3">
        {!product.images.length && (
          <span className="text-sm italic text-slate-600">Aucune image à afficher</span>
        )}
        {product.images.map((img, index) => (
          <ImageItem
            key={index}
            image={img}
            alt={`Photo de ${product.name} n°${index}`}
            imageClassName="h-80"
            updateImage={() => updateImage(img)}
            deleteImage={() => deleteImage(img)}
          />
        ))}
      </div>
    </div>
  );
}

export default ProductItem;
