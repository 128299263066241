import React from 'react';
import { Chart } from 'primereact/chart';

function VisitsChart({ visits }) {
  const chartOptions = {
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  };

  return <Chart type="bar" data={visits} options={chartOptions} className="h-[600px]" />;
}

export default VisitsChart;
