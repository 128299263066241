import React from 'react';
import Section from '../../../../components/Display/Section';
import Data from '../../../../components/Display/Data';
import DataTag from '../../../../components/Display/DataTag';
import MultipleDataTag from '../../../../components/Display/MultipleDataTag';
import MultiLineData from '../../../../components/Display/MultiLineData';
import ImageItem from '../../common/image/ImageItem';
import { confirmDialog } from 'primereact/confirmdialog';
import Tag from '../../../../components/Display/Tag';
import moment from 'moment';
import { Button } from 'primereact/button';

function ProjectDetail({
  project,
  handleUpdateProject,
  handleCreateImage,
  handleUpdateImage,
  handleDeleteImage,
  handleDelete
}) {
  const newImageActions = [
    {
      onClick: handleCreateImage,
      label: 'Ajouter une image',
      icon: 'pi-plus'
    }
  ];

  const updateProjectActions = [
    {
      onClick: handleUpdateProject,
      label: 'Modifier les informations',
      icon: 'pi-pencil'
    }
  ];

  const confirmImageDeletion = (image) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cette image ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteImage(image)
    });
  };

  const confirmDeletion = (project) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer ce Projet ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDelete(project)
    });
  };

  return (
    <div className={`h-full w-full flex-col flex gap-5`}>
      <Section label="Informations Générales" actions={updateProjectActions}>
        <div className="flex flex-wrap gap-5">
          <Data key="title" label={'Titre'} value={project.title} large />

          <Data key="city" label={'Ville'} value={project.city} large />
          <Data key="date" label={'Date'} value={moment(project.date).format('LL')} large />
          <Data key="client" label={'Client'} value={project.client} large />
          <Data
            key="client_url"
            label={'Site Internet du client'}
            value={project.client_url}
            large
          />
          <Data key="photos_credits" label={'Crédits Photos'} value={project.photo_credits} large />
        </div>

        <Tag
          tag={{
            label: project.is_highlight ? 'Projet mis en avant' : 'Projet non mis en avant',
            icon: 'star',
            bgColor: project.is_highlight ? 'bg-green-700' : 'bg-slate-400'
          }}
          rounded
        />

        <div className="flex flex-wrap gap-5">
          <MultiLineData
            key="description"
            label={'Description'}
            value={project.description}
            large
            asHtml
          />
        </div>
      </Section>
      <Section label="Images" actions={newImageActions}>
        <div className="flex flex-wrap gap-3">
          {!project.images.length && (
            <span className="italic text-slate-400 font-light">Aucune image à afficher</span>
          )}
          {project.images.map((img, index) => (
            <ImageItem
              key={index}
              image={img}
              alt={`Photo de ${project.title} n°${index}`}
              imageClassName="h-60"
              updateImage={() => handleUpdateImage(img)}
              deleteImage={() => confirmImageDeletion(img)}
            />
          ))}
        </div>
      </Section>
      <Section label="Autres actions">
        <div className="flex flex-row">
          <Button
            key={'delete'}
            label={'Supprimer le projet'}
            className="!w-54"
            outlined
            onClick={() => confirmDeletion(project)}
            severity="danger"
          />
        </div>
      </Section>
    </div>
  );
}

export default ProjectDetail;
