import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../common/navbar/Navbar';
import NewsPage from './news/NewsPage';
import ProductPage from './product/ProductPage';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import Welcome from './Welcome';
import ContentPage from './content/ContentPage';

function Home() {
  const { t } = useTranslation();
  const { navigationContext } = useNavigationContext();
  return (
    <div className="flex flex-row h-screen w-screen">
      <div className="min-w-64 w-64">
        <Navbar />
      </div>
      <div className="w-full p-5 h-auto overflow-scroll">
        <ContentPage isActive={navigationContext.acitveAppKey === 'content'} />
        <NewsPage isActive={navigationContext.acitveAppKey === 'news'} />
        <ProductPage isActive={navigationContext.acitveAppKey === 'product'} />
        <Welcome isActive={!navigationContext.acitveAppKey} />
      </div>
    </div>
  );
}

export default Home;
