import React from 'react';
import Data from '../../../../components/Display/Data';
import { Button } from 'primereact/button';
import MultiLineData from '../../../../components/Display/MultiLineData';
import { getConstant } from '../../../../utils/constants';

function NewsListItem({ news, deleteNews, updateNews }) {
  return (
    <div className="flex flex-row w-full justify-between p-5 rounded-lg shadow mb-5">
      <div className={`w-full mb-4 flex flex-col gap-y-3`}>
        <span className="text-lg font-semibold text-slate-700">
          {getConstant('banquetceleste.availableNewsSources', news.source_label).label}
        </span>
        <div className="flex-row flex flex-wrap gap-3">
          <Data key="source_url" label={"Lien de l'article"} value={news.source_url} large />
          <MultiLineData key="content" label={'Contenu'} value={news.content} large />
        </div>
      </div>
      <div className="flex gap-2">
        <Button
          rounded
          icon="pi pi-pencil"
          severity="success"
          onClick={() => updateNews(news)}
          outlined
          size="small"
          className="!h-9 !w-9"
        />
        <Button
          rounded
          icon="pi pi-trash"
          severity="danger"
          onClick={() => deleteNews(news)}
          outlined
          size="small"
          className="!h-9 !w-9"
        />
      </div>
    </div>
  );
}

export default NewsListItem;
