import React from 'react';
import { getImage, getImageFromBackend } from '../../../../utils/imageHelper';
import { SpeedDial } from 'primereact/speeddial';

function ImageItem({
  image,
  alt,
  imageClassName,
  updateImage,
  deleteImage,
  previewMode = false,
  speedDialClassName
}) {
  const actions = [
    {
      label: 'Modifier',
      icon: 'pi pi-pencil',
      visible: updateImage !== undefined,
      command: () => {
        updateImage();
      }
    },
    {
      label: 'Supprimer',
      icon: 'pi pi-trash',
      visible: deleteImage !== undefined,
      command: () => {
        deleteImage();
      }
    }
  ];

  return (
    <div key={image.id} className="flex flex-col gap-y-1 relative">
      <img
        src={image.file || getImage('img-ph.png')}
        className={`${imageClassName} object-cover`}
        alt={alt}
      />
      {image.label && <span className="text-slate-600 text-sm pl-1 h-6">{image.label}</span>}
      {!previewMode && (
        <SpeedDial
          key="image-deletion"
          className={`absolute bottom-12 right-3 text-xs ${speedDialClassName}`}
          buttonClassName={'!h-9 !w-9'}
          model={actions}
          direction="up"
          rotateAnimation={false}
          showIcon="pi pi-ellipsis-v"
          hideIcon="pi pi-times"
        />
      )}
    </div>
  );
}

export default ImageItem;
