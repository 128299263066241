import React from 'react';
import { Button } from 'primereact/button';
import Tag from '../../../../components/Display/Tag';
import { getImage } from '../../../../utils/imageHelper';
import moment from 'moment';
import { ProgressBar } from 'primereact/progressbar';

function EventItem({ event, handleClickEvent }) {
  return (
    <div
      className="flex flex-row items-center py-5 px-3 gap-x-5 cursor-pointer hover:bg-slate-100 w-full h-44"
      onClick={handleClickEvent}>
      <img
        className="h-32 w-32 object-cover shadow-md block rounded-md"
        src={event.image ? event.image.file : getImage('img-ph.png')}
        alt={event.image ? event.image.label : 'Non renseignée'}
      />
      <div className="flex flex-col justify-around h-full w-full">
        <div className="flex flex-col gap-y-1">
          <div className="text-xl font-bold text-slate-700 ">{event.title}</div>
          <span className="font-medium text-slate-700">{moment(event.start_dt).format('LLL')}</span>
        </div>
        <div className="flex flex-row gap-x-3">
          {event.properties.map((property) => (
            <Tag
              tag={{
                label: property.label,
                icon: 'resource',
                bgColor: 'bg-sky-950'
              }}
              key={property.id}
            />
          ))}
        </div>
        <div className="flex flex-row items-start w-full justify-between gap-5">
          <div className="font-bold">{event.attendees.length}</div>
          <ProgressBar value={event.gauge} className="w-full" />

          <div className="font-bold">{event.max_attendee_nb}</div>
        </div>
      </div>
    </div>
  );
}

export default EventItem;
