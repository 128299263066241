import React, { useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import AppLoader from './pages/AppLoader';
import SignIn from './pages/public/Signin/SignIn';
import CaeterraRouter from './pages/private/caeterra/Router';
import NouvelleParentheseRouter from './pages/private/nouvelleparenthese/Router';
import BanquetCelesteRouter from './pages/private/banquetceleste/Router';
import JeuneDargentreRouter from './pages/private/jeunedargentre/Router';
import MaisonMousseRouter from './pages/private/maisonmousse/Router';
import DejaDemainRouter from './pages/private/dejademain/Router';
import useScreenSize from './hooks/useScreenSize';
import MobileCatcher from './pages/MobileCatcher';

import { useAccountContext } from './contexts/AccountContext';
import RecoverPassword from './pages/public/RecoverPassword/RecoverPassword';
import PasswordSetup from './pages/public/PasswordSetUp/PasswordSetUp';

function Router() {
  const { isLoggedIn, accountContext } = useAccountContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const screenSize = useScreenSize();

  useEffect(() => {
    if (!isLoggedIn && !pathname.startsWith('/password-setup')) {
      navigate('/');
    }
  }, [accountContext]);

  useEffect(() => {
    if (screenSize.width < 700) {
      navigate('/mobile-catcher');
    }
  }, []);

  const getScopeRouter = () => {
    if (!isLoggedIn || !accountContext.scope) return null;
    switch (accountContext.scope.slug) {
      case 'caeterra':
        return <CaeterraRouter />;
      case 'nouvelleparenthese':
        return <NouvelleParentheseRouter />;
      case 'banquetceleste':
        return <BanquetCelesteRouter />;
      case 'jeunedargentre':
        return <JeuneDargentreRouter />;
      case 'maisonmousse':
        return <MaisonMousseRouter />;
      case 'dejademain':
        return <DejaDemainRouter />;
      default:
        console.log(`Error : Unknown Scope slug : ${accountContext.scope.slug}`);
        break;
    }
  };
  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route exact path="/login" element={<SignIn />} />
        <Route path="/" element={<AppLoader />} />
        <Route path="/recover-password" element={<RecoverPassword />} />
        <Route exact path="/password-setup/:token" element={<PasswordSetup />} />
        <Route exact path="/mobile-catcher" element={<MobileCatcher />} />
      </Routes>
      {/* Private routes */}
      {getScopeRouter()}
    </>
  );
}

export default Router;
