import React from 'react';
import { getImage } from '../../utils/imageHelper';
import { CONSTANT } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

function Layout({ children }) {
  const { t } = useTranslation();

  return (
    <div className="h-screen w-screen bg-slate-700 text-slate-50">
      <div className="h-full flex flex-col justify-between p-5">
        <div className="flex flex-row gap-5 items-center">
          <img
            src={getImage('logo-node-min.png')}
            alt="Logo Collectif Node"
            className="h-32 w-32"
          />
        </div>
        {children}
        <div className="flex flex-row gap-3 text-slate-300">
          <span>{t('login.version', { version: CONSTANT.version })}</span>
          <span>-</span>
          <span>
            {`Conçu, développé et maintenu par le `}{' '}
            <a
              href="https://collectifnode.fr"
              target="_blank"
              rel="noreferrer"
              className="text-yellow-500 hover:underline">
              Collectif Node
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Layout;
