import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../components/Display/PageWrapper';
import VisitsChart from './VisitsChart';
import { privateFetch } from '../../../utils/apiHelper';
import { useAccountContext } from '../../../contexts/AccountContext';
import { Divider } from 'primereact/divider';
import Section from '../../../components/Display/Section';

function AnalyticsPage({ isActive, scope }) {
  const { accountContext } = useAccountContext();
  const [visitsForAllPaths, setVisitsForAllPath] = useState({});
  const [visitsForProgramSubpaths, setVisitsForProgramSubpaths] = useState({});
  const [visitsForResourceSubpaths, setVisitsForResourceSubpaths] = useState({});

  const fetchVisitsForAllPath = async () => {
    await privateFetch('GET', 'analytics', `/cu/${accountContext.id}/visit/?scope=${scope}`).then(
      (res) => {
        setVisitsForAllPath(res);
      }
    );
  };

  const fetchVisitsForProgramSubpaths = async () => {
    await privateFetch(
      'GET',
      'analytics',
      `/cu/${accountContext.id}/visit/path/programmes/?scope=${scope}`
    ).then((res) => {
      setVisitsForProgramSubpaths(res);
    });
  };

  const fetchVisitsForResourceSubpaths = async () => {
    await privateFetch(
      'GET',
      'analytics',
      `/cu/${accountContext.id}/visit/path/ressources/?scope=${scope}`
    ).then((res) => {
      setVisitsForResourceSubpaths(res);
    });
  };

  useEffect(() => {
    fetchVisitsForAllPath();
    fetchVisitsForProgramSubpaths();
    fetchVisitsForResourceSubpaths();
  }, []);

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      <PageWrapper label="Mesure d'audience">
        <Section label="Visites par pages">
          <h4 className="italic text-gray-600">Affichage sur les 12 derniers mois</h4>
          <VisitsChart key="all-paths" visits={visitsForAllPaths} />
        </Section>

        <Divider />

        <Section label="Détail des visites par programme">
          <h4 className="italic text-gray-600">Affichage sur les 12 derniers mois</h4>
          <VisitsChart key="programm-subpaths" visits={visitsForProgramSubpaths} />
        </Section>

        <Divider />

        <Section label="Détail des visites par ressource">
          <h4 className="italic text-gray-600">Affichage sur les 12 derniers mois</h4>
          <VisitsChart key="resources-subpaths" visits={visitsForResourceSubpaths} />
        </Section>
      </PageWrapper>
    </div>
  );
}

export default AnalyticsPage;
