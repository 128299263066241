import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { CONSTANT } from '../../../../utils/constants';
import FormInput from '../../../../components/Form/FormInput';

function ContentDocumentPanel({ isOpen, handleClose, updateDocument, loading, document }) {
  const fileUploadRef = useRef(null);

  // Mandatory
  const [file, setFile] = useState(undefined);
  const [fileName, setFileName] = useState(undefined);
  const [type, setType] = useState(undefined);
  const [filePath, setFilePath] = useState('');

  useEffect(() => {
    if (document) {
      setFilePath(document.file);
      setType(document.type);
      setFileName('');
      setFile(undefined);
    }
  }, [isOpen]);

  const onValidate = () => {
    const formData = new FormData();
    formData.append('type', type);
    // If file has changed
    formData.append('file', file);
    updateDocument(formData);
  };

  const onUpload = (event) => {
    if (event.files.length === 1) {
      const file = event.files[0];
      setFilePath(file.objectURL);
      setFileName(file.name);
      setFile(file);
    }
  };

  const handleOpenDocument = () => {
    window.open(filePath, '_blank');
  };

  const handleRemoveFile = () => {
    setFile(undefined);
    setFileName(undefined);
    setFilePath(document.file);
    fileUploadRef.current.clear();
  };

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Modifier le document`}
        loading={loading}
        disabled={!file}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  const customHeader = (
    <React.Fragment>
      <h2 style={{ marginBottom: 0 }} className="text-grey-800 text-2xl font-medium">
        {document
          ? `${CONSTANT.caeterra.documentTypes.find((dt) => dt.value === document.type).label}`
          : 'Modifier un document'}
      </h2>
    </React.Fragment>
  );

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={customHeader}
      showCloseIcon={true}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => handleClose()}
      position="right">
      {document && (
        <div className="sidebar-content-container">
          <div className="form-wrapper">
            <FormInput label={'Fichier actuel'}>
              <div style={{ width: 'fit-content' }}>
                <Button
                  label={'Ouvrir le document'}
                  outlined
                  icon="pi pi-eye"
                  onClick={handleOpenDocument}
                />
              </div>
            </FormInput>
            <FormInput label={'Nouveau fichier PDF'}>
              {fileName && (
                <div className="flex gap-x-3 px-2 text-green-700 font-medium items-center mb-2 mt-1">
                  <span className="pi pi-check"></span>
                  <span>{fileName}</span>
                </div>
              )}
              <div className="flex flex-row gap-x-5">
                {file && (
                  <Button
                    key={'delete-img'}
                    label={'Annuler'}
                    outlined
                    severity="warning"
                    onClick={handleRemoveFile}
                  />
                )}
                <FileUpload
                  ref={fileUploadRef}
                  auto
                  disabled={file}
                  mode="basic"
                  name="file"
                  accept="application/pdf"
                  maxFileSize={CONSTANT.maxImageSize}
                  customUpload
                  uploadHandler={onUpload}
                  chooseLabel="Depuis mon ordinateur"
                />
              </div>
            </FormInput>
          </div>
          {footerButtons}
        </div>
      )}
    </Sidebar>
  );
}

export default ContentDocumentPanel;
