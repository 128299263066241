import React, { useEffect, useState } from 'react';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import PageWrapper from '../../../../components/Display/PageWrapper';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import { DataView } from 'primereact/dataview';
import ProductItem from './ProductItem';
import ImagePanel from '../../common/image/ImagePanel';
import ProductDetail from './ProductDetail';
import ProductPanel from './ProductPanel';

function ProductPage({ isActive }) {
  const { openProductDetail, navigationContext } = useNavigationContext();
  const { accountContext } = useAccountContext();
  const [productList, setProductList] = useState([]);
  const [productDetail, setProductDetail] = useState(undefined);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [imageManagerIsOpen, setImageManagerIsOpen] = useState(false);

  const fetchProductList = async () => {
    await privateFetch('GET', 'dejademain', `/cu/${accountContext.id}/product/`).then((res) => {
      setProductList(res);
    });
  };

  useEffect(() => {
    fetchProductList();
  }, []);

  const fetchProductDetail = async () => {
    await privateFetch(
      'GET',
      'dejademain',
      `/cu/${accountContext.id}/product/${navigationContext.acitveProductId}/`
    ).then((res) => {
      if (res) setProductDetail(res);
    });
  };

  useEffect(() => {
    if (navigationContext.acitveProductId) {
      fetchProductDetail();
    } else setProductDetail(undefined);
  }, [navigationContext]);

  const handleClickProduct = (product) => {
    openProductDetail(product);
  };

  const handleOpenManager = () => {
    setPanelIsOpen(true);
  };

  const createProduct = async (productForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'dejademain',
      `/cu/${accountContext.id}/product/`,
      'Le produit a bien été ajouté',
      JSON.stringify(productForm)
    ).then(() => {
      fetchProductList();
      setPanelIsOpen(false);
      setLoading(false);
    });
  };

  const updateProduct = async (productForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'dejademain',
      `/cu/${accountContext.id}/product/${productDetail.id}/`,
      'Le produit a bien été modifié',
      JSON.stringify(productForm)
    ).then(() => {
      fetchProductList();
      setPanelIsOpen(false);
      fetchProductDetail(productDetail.id);
      setLoading(false);
    });
  };

  const handleDeleteProduct = async (product) => {
    await privateFetch(
      'DELETE',
      'dejademain',
      `/cu/${accountContext.id}/product/${product.id}/`,
      'Le produit a bien été supprimé'
    ).then(() => {
      fetchProductList();
      setProductDetail(undefined);
    });
  };

  const newProductAction = {
    onClick: handleOpenManager,
    label: 'Ajouter un produit',
    icon: 'pi-plus'
  };

  const goBackProductAction = {
    onClick: handleClickProduct,
    label: 'Retour à la liste'
  };

  const handleCreateImage = () => {
    setSelectedImage(undefined);
    setImageManagerIsOpen(true);
  };

  const handleUpdateImage = (image) => {
    setSelectedImage(image);
    setImageManagerIsOpen(true);
  };

  const handleDeleteImage = async (image) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/image/${image.id}/`,
      "L'image a bien été supprimée"
    ).then(() => {
      fetchProductDetail();
      fetchProductList();

      setSelectedImage(undefined);
    });
  };

  const uploadResourceImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'dejademain',
      `/cu/${accountContext.id}/product/${productDetail.id}/image/`,
      "L'image a bien été enregistrée",
      imageForm,
      false,
      true
    ).then(() => {
      fetchProductDetail();
      fetchProductList();

      setLoading(false);
      setImageManagerIsOpen(false);
    });
  };

  const updateImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/image/${selectedImage.id}/`,
      "L'image a bien été modifiée",
      imageForm,
      false,
      true
    ).then(() => {
      fetchProductDetail();
      fetchProductList();
      setLoading(false);
      setImageManagerIsOpen(false);
    });
  };

  const itemTemplate = (product) => (
    <ProductItem product={product} handleClickProduct={() => handleClickProduct(product)} />
  );

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      <PageWrapper
        label="Produits"
        labelAction={handleClickProduct}
        action={productDetail ? goBackProductAction : newProductAction}
        detail={productDetail}>
        {!productDetail ? (
          <DataView value={productList} itemTemplate={itemTemplate} className="pb-20" />
        ) : (
          <ProductDetail
            product={productDetail}
            handleUpdateProduct={handleOpenManager}
            handleCreateImage={handleCreateImage}
            handleUpdateImage={handleUpdateImage}
            handleDeleteImage={handleDeleteImage}
            handleDelete={handleDeleteProduct}
          />
        )}

        <ProductPanel
          isOpen={panelIsOpen}
          onClose={setPanelIsOpen}
          product={productDetail}
          onCreate={createProduct}
          onUpdate={updateProduct}
          loading={loading}
        />
        <ImagePanel
          image={selectedImage}
          loading={loading}
          isOpen={imageManagerIsOpen}
          onClose={() => {
            setSelectedImage(null);
            setImageManagerIsOpen(false);
          }}
          onCreate={uploadResourceImage}
          onUpdate={updateImage}
        />
      </PageWrapper>
    </div>
  );
}

export default ProductPage;
