import React from 'react';
import DocumentItem from './DocumentItem';

function DocumentList({ documents, onUpdate }) {
  if (!documents.length)
    return <span className="empty-section-message">Aucun document à afficher...</span>;
  return (
    <div className="flex flex-row flex-wrap gap-x-5 gap-y-5">
      {documents.map((d, i) => (
        <DocumentItem key={i} document={d} onUpdate={() => onUpdate(d)} />
      ))}
    </div>
  );
}

export default DocumentList;
