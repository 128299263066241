import React, { useState, useEffect } from 'react';
import PageWrapper from '../../../../components/Display/PageWrapper';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import SectionDetail from './SectionDetail';
import SectionPanel from './SectionPanel';
import DocumentPanel from '../../common/document/DocumentPanel';
import NewsPanel from './NewsPanel';
import ImagePanel from '../../common/image/ImagePanel';

function SectionPage({ isActive, section }) {
  const { accountContext } = useAccountContext();
  const [sectionDetail, setSectionDetail] = useState([]);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentManagerIsOpen, setDocumentManagerIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(undefined);
  const [selectedNews, setSelectedNews] = useState(undefined);
  const [newsManagerIsOpen, setNewsManagerIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [imageManagerIsOpen, setImageManagerIsOpen] = useState(false);

  const fetchSectionDetail = async () => {
    await privateFetch(
      'GET',
      'jeunedargentre',
      `/cu/${accountContext.id}/section/${section.id}/`
    ).then((res) => {
      if (res) setSectionDetail(res);
    });
  };

  useEffect(() => {
    fetchSectionDetail();
  }, [section]);

  const handleUpdateSection = () => {
    setPanelIsOpen(true);
  };

  const updateSection = async (sectionForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'jeunedargentre',
      `/cu/${accountContext.id}/section/${section.id}/`,
      'La section a bien été modifiée',
      JSON.stringify(sectionForm)
    ).then(() => {
      fetchSectionDetail();
      setPanelIsOpen(false);
      setLoading(false);
    });
  };

  const handleCreateNews = () => {
    setSelectedNews(undefined);
    setNewsManagerIsOpen(true);
  };

  const handleUpdateNews = (news) => {
    setSelectedNews(news);
    setNewsManagerIsOpen(true);
  };

  const createSectionNews = async (newsForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'jeunedargentre',
      `/cu/${accountContext.id}/section/${section.id}/news/`,
      "L'article a bien été enregistré",
      JSON.stringify(newsForm)
    ).then(() => {
      fetchSectionDetail();
      setLoading(false);
      setNewsManagerIsOpen(false);
    });
  };

  const updateNews = async (newsForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/news/${selectedNews.id}/`,
      "L'article a bien été modifiée",
      JSON.stringify(newsForm)
    ).then(() => {
      fetchSectionDetail();
      setLoading(false);
      setNewsManagerIsOpen(false);
    });
  };

  const handleDeleteNews = async (news) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/news/${news.id}/`,
      "L'article a bien été supprimée"
    ).then(() => {
      fetchSectionDetail();
      setNewsManagerIsOpen(false);
    });
  };
  const handleCreateDocument = () => {
    setSelectedDocument(undefined);
    setDocumentManagerIsOpen(true);
  };

  const handleUpdateDocument = (document) => {
    setSelectedDocument(document);
    setDocumentManagerIsOpen(true);
  };

  const uploadSectionDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'jeunedargentre',
      `/cu/${accountContext.id}/section/${section.id}/file/`,
      'Le fichier a bien été enregistrée',
      documentForm,
      false,
      true
    ).then(() => {
      fetchSectionDetail();
      setLoading(false);
      setDocumentManagerIsOpen(false);
    });
  };

  const updateDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/file/${selectedDocument.id}/`,
      'Le document a bien été modifiée',
      documentForm,
      false,
      true
    ).then(() => {
      fetchSectionDetail();
      setLoading(false);
      setDocumentManagerIsOpen(false);
    });
  };

  const handleDeleteDocument = async (document) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/file/${document.id}/`,
      'Le document a bien été supprimée'
    ).then(() => {
      fetchSectionDetail();
      setSelectedDocument(undefined);
    });
  };

  const handleCreateImage = () => {
    setSelectedImage(undefined);
    setImageManagerIsOpen(true);
  };

  const handleUpdateImage = (image) => {
    setSelectedImage(image);
    setImageManagerIsOpen(true);
  };

  const uploadSectionImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'jeunedargentre',
      `/cu/${accountContext.id}/section/${section.id}/image/`,
      "L'image a bien été enregistrée",
      imageForm,
      false,
      true
    ).then(() => {
      fetchSectionDetail();
      setLoading(false);
      setImageManagerIsOpen(false);
    });
  };

  const updateImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/image/${selectedImage.id}/`,
      "L'image a bien été modifiée",
      imageForm,
      false,
      true
    ).then(() => {
      fetchSectionDetail();
      setLoading(false);
      setImageManagerIsOpen(false);
    });
  };

  const handleDeleteImage = async (image) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/image/${image.id}/`,
      "L'image a bien été supprimée"
    ).then(() => {
      fetchSectionDetail();
      setSelectedImage(undefined);
    });
  };

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      <PageWrapper label={sectionDetail.title}>
        <SectionDetail
          section={sectionDetail}
          handleUpdateSection={handleUpdateSection}
          handleCreateFile={handleCreateDocument}
          handleDeleteFile={handleDeleteDocument}
          handleUpdateFile={handleUpdateDocument}
          handleUpdateNews={handleUpdateNews}
          handleCreateNews={handleCreateNews}
          handleCreateImage={handleCreateImage}
          handleUpdateImage={handleUpdateImage}
          handleDeleteImage={handleDeleteImage}
        />
      </PageWrapper>
      <SectionPanel
        isOpen={panelIsOpen}
        onClose={setPanelIsOpen}
        section={sectionDetail}
        onUpdate={updateSection}
        loading={loading}
      />
      <DocumentPanel
        document={selectedDocument}
        loading={loading}
        isOpen={documentManagerIsOpen}
        onClose={() => {
          setSelectedDocument(null);
          setDocumentManagerIsOpen(false);
        }}
        onCreate={uploadSectionDocument}
        onUpdate={updateDocument}
      />
      <NewsPanel
        isOpen={newsManagerIsOpen}
        onClose={setNewsManagerIsOpen}
        news={selectedNews}
        loading={loading}
        onCreate={createSectionNews}
        onUpdate={updateNews}
        handleDeleteNews={handleDeleteNews}
        section={sectionDetail.slug}
      />

      <ImagePanel
        image={selectedImage}
        loading={loading}
        isOpen={imageManagerIsOpen}
        onClose={() => {
          setSelectedImage(null);
          setImageManagerIsOpen(false);
        }}
        onCreate={uploadSectionImage}
        onUpdate={updateImage}
      />
    </div>
  );
}

export default SectionPage;
