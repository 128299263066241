import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { CONSTANT } from '../../../../utils/constants';
import FormInput from '../../../../components/Form/FormInput';
import { InputText } from 'primereact/inputtext';

function DocumentPanel({ isOpen, onClose, onCreate, onUpdate, loading, document }) {
  const fileUploadRef = useRef(null);

  // Mandatory
  const [file, setFile] = useState(undefined);
  const [fileName, setFileName] = useState(undefined);
  const [type, setType] = useState(undefined);
  const [filePath, setFilePath] = useState('');
  const [label, setLabel] = useState('');

  useEffect(() => {
    if (document) {
      setFilePath(document.file);
      setType(document.type);
      setFileName('');
      setFile(undefined);
      setLabel(document.label);
    } else {
      setFilePath(undefined);
      setFileName('');
      setType(undefined);
      setFile(undefined);
      setLabel('');
    }
  }, [isOpen]);

  const onValidate = () => {
    const formData = new FormData();
    formData.append('type', type);
    formData.append('label', label);
    // If file has changed
    file && formData.append('file', file);
    document ? onUpdate(formData) : onCreate(formData);
  };

  const onUpload = (event) => {
    if (event.files.length === 1) {
      const file = event.files[0];
      setFilePath(file.objectURL);
      setFileName(file.name);
      setFile(file);
    }
  };

  const handleOpenDocument = () => {
    window.open(filePath, '_blank');
  };

  const handleRemoveFile = () => {
    setFile(undefined);
    setFileName(undefined);
    setFilePath(document.file);
    fileUploadRef.current.clear();
  };

  const formIsValid = label.length > 3 && filePath;

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Valider le document`}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  const customHeader = (
    <React.Fragment>
      <h2 style={{ marginBottom: 0 }} className="text-grey-800 text-2xl font-medium">
        {document ? `Modifier un document` : 'Ajouter un document'}
      </h2>
    </React.Fragment>
  );

  return (
    <Sidebar
      style={{ width: 600 }}
      header={customHeader}
      showCloseIcon={true}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={() => onClose()}
      position="right">
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={'Nom du fichier'} required isValid={label.length > 3}>
            <InputText
              id="label"
              value={label}
              maxLength={300}
              placeholder="Veuillez saisir le titre du document"
              onChange={(e) => setLabel(e.target.value)}
            />
          </FormInput>

          {document && (
            <FormInput label={'Fichier actuel'}>
              <div style={{ width: 'fit-content' }}>
                <Button
                  label={'Ouvrir le document'}
                  outlined
                  icon="pi pi-eye"
                  onClick={handleOpenDocument}
                />
              </div>
            </FormInput>
          )}

          <FormInput label={'Nouveau fichier PDF'}>
            {fileName && (
              <div className="flex gap-x-3 px-2 text-green-700 font-medium items-center mb-2 mt-1">
                <span className="pi pi-check"></span>
                <span>{fileName}</span>
              </div>
            )}
            <div className="flex flex-row gap-x-5">
              {file && (
                <Button
                  key={'delete-img'}
                  label={'Annuler'}
                  outlined
                  severity="warning"
                  onClick={handleRemoveFile}
                />
              )}
              <FileUpload
                ref={fileUploadRef}
                auto
                disabled={file}
                mode="basic"
                name="file"
                accept="application/pdf"
                maxFileSize={CONSTANT.maxImageSize}
                customUpload
                uploadHandler={onUpload}
                chooseLabel="Depuis mon ordinateur"
              />
            </div>
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default DocumentPanel;
