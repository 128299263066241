import React from 'react';
import { Button } from 'primereact/button';
import Tag from '../../../../components/Display/Tag';
import { getImage } from '../../../../utils/imageHelper';
import moment from 'moment';

function ProjectItem({ project, handleClickProject }) {
  return (
    <div
      className="flex flex-row items-start py-5 px-3 gap-x-5 cursor-pointer hover:bg-slate-100"
      onClick={handleClickProject}>
      <img
        className="h-32 w-32 object-cover shadow-md block rounded-md"
        src={project.images[0]?.file ? project.images[0].file : getImage('img-ph.png')}
        alt={project.images[0]?.label ? project.images[0].label : 'Non renseignée'}
      />
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-y-1">
          <div className="text-xl font-bold text-slate-700 ">{project.title}</div>
          <span className="font-medium text-slate-700">{`${project.city}, ${moment(project.date).format('LL')}`}</span>

          <span className="font-small text-slate-700">{project.client}</span>
        </div>
        <div className="flex flex-row gap-x-3">
          <Tag
            tag={{
              label: project.is_highlight && 'Projet mis en avant',
              icon: 'star',
              bgColor: project.is_highlight && 'bg-green-700'
            }}
            rounded
          />
        </div>
      </div>
    </div>
  );
}

export default ProjectItem;
