import React, { useEffect } from 'react';
import Welcome from './Welcome';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import Navbar from '../common/navbar/Navbar';
import './JeuneDargentre.scss';
import { useAccountContext } from '../../../contexts/AccountContext';
import { privateFetch } from '../../../utils/apiHelper';
import { useState } from 'react';
import SectionPage from './section/SectionPage';

function Home() {
  const { navigationContext } = useNavigationContext();
  const { accountContext } = useAccountContext();
  const [sectionList, setSectionList] = useState([]);

  const fetchSectionList = async () => {
    await privateFetch('GET', 'jeunedargentre', `/cu/${accountContext.id}/section/`).then((res) => {
      setSectionList(res);
    });
  };

  useEffect(() => {
    fetchSectionList();
  }, []);

  return (
    <div className="flex flex-row h-screen w-screen">
      <div className="min-w-64 w-64">
        <Navbar isAdmin availableApps={sectionList} />
      </div>
      <div className="w-full h-auto overflow-scroll">
        {sectionList.map((section) => (
          <SectionPage
            isActive={navigationContext.acitveAppKey === section.slug}
            section={section}
          />
        ))}
        {<Welcome isActive={!navigationContext.acitveAppKey} />}
      </div>
    </div>
  );
}

export default Home;
