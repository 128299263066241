import React from 'react';
import Section from '../../../../components/Display/Section';
import MultiLineData from '../../../../components/Display/MultiLineData';
import Data from '../../../../components/Display/Data';
import DocumentItem from '../../common/document/DocumentItem';
import ImageItem from '../../common/image/ImageItem';
import { DataView } from 'primereact/dataview';
import NewsItem from './NewsItem';
import { confirmDialog } from 'primereact/confirmdialog';

function SectionDetail({
  section,
  handleUpdateSection,
  handleCreateFile,
  handleCreateImage,
  handleCreateNews,
  handleUpdateFile,
  handleDeleteFile,
  handleUpdateNews,
  handleUpdateImage,
  handleDeleteImage
}) {
  const updateSectionActions = [
    {
      onClick: handleUpdateSection,
      label: 'Modifier les informations',
      icon: 'pi-pencil'
    }
  ];

  const newFileActions = [
    {
      onClick: handleCreateFile,
      label: 'Ajouter un fichier',
      icon: 'pi-plus'
    }
  ];

  const confirmDocumentDeletion = (document) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteFile(document)
    });
  };

  const newImageActions = [
    {
      onClick: handleCreateImage,
      label: 'Ajouter une image',
      icon: 'pi-plus'
    }
  ];

  const confirmImageDeletion = (image) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cette image ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteImage(image)
    });
  };

  const newNewsActions = [
    {
      onClick: handleCreateNews,
      label: 'Ajouter un article',
      icon: 'pi-plus'
    }
  ];

  const itemTemplate = (news) => (
    <NewsItem news={news} handleClicknews={() => handleUpdateNews(news)} />
  );

  return (
    <div>
      {section.slug !== 'association' && (
        <>
          <Section label="Informations Générales" actions={updateSectionActions}>
            <div className="flex flex-wrap gap-5 flex-col">
              <MultiLineData
                key="description"
                label={'Description'}
                value={section.description}
                large
                asHtml
              />
              <MultiLineData
                key="registration_method"
                label={"Modalités d'inscription"}
                value={section.registration_method}
                large
                asHtml
              />
              <MultiLineData
                key="registration_date"
                label={"Dates d'inscription"}
                value={section.registration_date}
                large
                asHtml
              />
              <MultiLineData
                key="timetable"
                label={'Horaires'}
                value={section.timetable}
                large
                asHtml
              />
              <MultiLineData key="pricing" label={'Tarifs'} value={section.pricing} large asHtml />
              <MultiLineData
                key="additionnal_information"
                label={'Informations supplémentaires'}
                value={section.additionnal_information}
                large
                asHtml
              />
              <Data
                key="instagram_url"
                label={'Page Instagram'}
                value={section.instagram_url}
                large
              />
              <Data key="facebook_url" label={'Page Facebook'} value={section.facebook_url} large />
              <Data
                key="website_url"
                label={'Site Internet Externe'}
                value={section.website_url}
                large
              />
            </div>
          </Section>
          <Section label="Fichiers téléchargeables" actions={newFileActions}>
            {section.documents?.length > 0 ? (
              <div className="flex flex-wrap gap-3">
                {section.documents.map((doc) => (
                  <DocumentItem
                    key={doc.id}
                    document={doc}
                    onDelete={() => confirmDocumentDeletion(doc)}
                    onUpdate={() => handleUpdateFile(doc)}
                  />
                ))}
              </div>
            ) : (
              <p className="italic text-slate-400 font-light">Non renseignés</p>
            )}
          </Section>
          <Section label="Images" actions={newImageActions}>
            <div className="flex flex-wrap gap-3">
              {section.images?.length ? (
                section.images.map((img, index) => (
                  <ImageItem
                    key={index}
                    image={img}
                    alt={`Photo de ${section.name} n°${index}`}
                    imageClassName="h-60"
                    updateImage={() => handleUpdateImage(img)}
                    deleteImage={() => confirmImageDeletion(img)}
                  />
                ))
              ) : (
                <span className="italic text-slate-400 font-light">Aucune image à afficher</span>
              )}
            </div>
          </Section>
        </>
      )}
      <Section label="Articles" actions={newNewsActions}>
        {section.news?.length ? (
          <DataView value={section.news} itemTemplate={itemTemplate} />
        ) : (
          <p className="italic text-slate-400 font-light">Non renseignés</p>
        )}
      </Section>
    </div>
  );
}

export default SectionDetail;
