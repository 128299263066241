import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import BanquetCelesteHome from '../banquetceleste/Home';

function Router() {
  return (
    <Routes>
      <Route exact path="/banquetceleste/home" element={<BanquetCelesteHome />} />
    </Routes>
  );
}

export default Router;
