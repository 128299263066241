import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import ImageItem from './ImageItem';
import { CONSTANT } from '../../../../utils/constants';

function MultipleImagePanel({ images, loading, isOpen, onCreate, onDelete, onClose }) {
  const fileUploadRef = useRef(null);
  const [file, setFile] = useState(undefined);
  const [filePreviewUrl, setFilePreviewUrl] = useState(undefined);

  useEffect(() => {
    setFilePreviewUrl(undefined);
    setFile(undefined);
  }, [isOpen]);

  const onUpload = (event) => {
    if (event.files.length == 1) {
      const file = event.files[0];
      setFilePreviewUrl(file.objectURL);
      setFile(file);
    }
  };

  const handleValidate = () => {
    const formData = new FormData();
    formData.append('file', file);
    onCreate(formData);
    setFilePreviewUrl(undefined);
    setFile(undefined);
    fileUploadRef.current.clear();
  };

  const handleRemoveImage = () => {
    setFile(undefined);
    setFilePreviewUrl(undefined);
    fileUploadRef.current.clear();
  };

  const customHeader = (
    <React.Fragment>
      <h2 style={{ marginBottom: 0 }} className="text-slate-900 text-2xl font-medium">
        {'Gérer les images'}
      </h2>
    </React.Fragment>
  );

  return (
    <Sidebar
      style={{ width: 1000 }}
      header={customHeader}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={onClose}
      position="right">
      <div className="flex flex-col gap-y-1">
        <label className="text-sm" htmlFor="name">
          Image existantes
        </label>
        <div className="flex flex-wrap gap-3">
          {images.map((i) => (
            <ImageItem
              key={i.id}
              imageClassName={'h-[200px]'}
              speedDialClassName={'bottom-5'}
              deleteImage={() => onDelete(i)}
              image={{
                file: i.file
              }}
              alt={'Prévisualisation de la photo'}
            />
          ))}
          {!images.length && (
            <span className="italic my-5 text-slate-400">
              Aucune image n'est liée à cette prestation
            </span>
          )}
        </div>
        <label className="text-sm mt-5" htmlFor="name">
          Nouvelle image
        </label>
        {loading ? (
          <div className="w-16 h-16 m-5">
            <ProgressSpinner style={{ width: '64px', height: '64px' }} />
          </div>
        ) : (
          <div className="flex flex-col gap-y-3 pb-20">
            <div className="flex">
              <ImageItem
                key={'preview'}
                previewMode
                imageClassName={'h-[300px]'}
                image={{
                  file: filePreviewUrl
                }}
                alt={'Prévisualisation de la photo'}
              />
            </div>
            <div className="flex flex-row gap-x-3">
              {filePreviewUrl && (
                <Button
                  key={'upload-img'}
                  label={"Valider l'image"}
                  onClick={handleValidate}
                  severity="success"
                />
              )}
              {filePreviewUrl && (
                <Button
                  key={'delete-img'}
                  label={"Supprimer l'image"}
                  outlined
                  onClick={handleRemoveImage}
                  severity="danger"
                />
              )}
              <FileUpload
                ref={fileUploadRef}
                auto
                disabled={file}
                mode="basic"
                name="file"
                accept="image/*"
                maxFileSize={CONSTANT.maxImageSize}
                customUpload
                uploadHandler={onUpload}
                chooseLabel="Depuis mon ordinateur"
              />
            </div>
          </div>
        )}
      </div>
    </Sidebar>
  );
}

export default MultipleImagePanel;
