import React from 'react';
import { getImage } from '../../../../utils/imageHelper';
import Tag from '../../../../components/Display/Tag';
import { getConstant } from '../../../../utils/constants';
import moment from 'moment';

function ResourceListItem({ resource, handleClickResource }) {
  return (
    <div
      className="w-full flex flex-row justify-between items-end p-5 cursor-pointer hover:bg-slate-100"
      onClick={handleClickResource}>
      <div className="h-full flex flex-row gap-x-5">
        <img
          className="h-32 w-32 object-cover shadow-md block rounded-md"
          src={resource.image ? resource.image.file : getImage('img-ph.png')}
          alt={resource.image ? resource.image.label : 'Non renseignée'}
        />

        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col gap-y-1">
            <div className="text-xl font-bold text-slate-700 ">{resource.title}</div>
            <span className="font-medium text-slate-700">{resource.author}</span>
          </div>
          <div className="flex flex-row gap-x-3">
            <Tag tag={getConstant('banquetceleste.common.statusTypes', resource.status)} rounded />
            <Tag tag={getConstant('banquetceleste.resourceTypes', resource.type)} rounded />
          </div>
        </div>
      </div>
      <p className="text-sm italic">{moment(resource.date).format('DD/MM/YYYY')}</p>
    </div>
  );
}

export default ResourceListItem;
