import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import JeuneDargentre from '../jeunedargentre/Home';

function Router() {
  return (
    <Routes>
      <Route exact path="/jeunedargentre/home" element={<JeuneDargentre />} />
    </Routes>
  );
}

export default Router;
