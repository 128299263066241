import React from 'react';
import Section from '../../../../components/Display/Section';
import Data from '../../../../components/Display/Data';
import moment from 'moment';
import MultipleDataTag from '../../../../components/Display/MultipleDataTag';
import MultiLineData from '../../../../components/Display/MultiLineData';
import { ProgressBar } from 'primereact/progressbar';
import { getImage } from '../../../../utils/imageHelper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

function EventDetail({ event, handleUpdateEvent, handleDelete, handleDeleteAttendee }) {
  const updateEventActions = [
    {
      onClick: handleUpdateEvent,
      label: 'Modifier les informations',
      icon: 'pi-pencil'
    }
  ];

  const confirmDeletion = (event) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cet Évènement ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDelete(event)
    });
  };

  const confirmDeleteAttendee = (attendee) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer ce Participant ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteAttendee(attendee)
    });
  };

  const actionBodyTemplate = (attendee) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteAttendee(attendee)}
        />
      </React.Fragment>
    );
  };

  return (
    <div className={`h-full w-full flex-col flex gap-5`}>
      <Section label="Informations Générales" actions={updateEventActions}>
        <div className="flex flex-wrap gap-5">
          <Data key="title" label={'Titre'} value={event.title} large />
          <Data key="place_name" label={'Lieu'} value={event.place_name} large />
          <Data key="organizer" label={'Organisateur'} value={event.organizer} large />
          <Data
            key="start_dt"
            label={'Date de début'}
            value={moment(event.start_dt).format('LLL')}
            large
          />
          <Data
            key="end_dt"
            label={'Date de fin'}
            value={moment(event.end_dt).format('LLL')}
            large
          />
          <Data
            key="max_attendee_nb"
            label={'Nombre de participant maximum'}
            value={event.max_attendee_nb}
            large
          />
        </div>
        <div className="flex flex-wrap gap-5">
          <MultipleDataTag
            key="properties"
            label={'Propriétés'}
            tags={event.properties.map((property) => ({
              label: property.label
            }))}
          />
        </div>

        <div className="flex flex-wrap gap-5">
          <MultiLineData
            key="description"
            label={'Description'}
            value={event.description}
            large
            asHtml
          />
        </div>
      </Section>
      <Section label="Image principale">
        <img
          className="w-96"
          src={event.image ? event.image.file : getImage('img-ph.png')}
          alt={event.image ? event.image.label : 'Non renseignée'}
        />
      </Section>
      <Section label="Taux d'inscription">
        <div className="flex flex-row items-start w-full justify-between gap-5">
          <div className="font-bold">{event.attendees.length}</div>
          <ProgressBar value={event.gauge} className="w-full" />

          <div className="font-bold">{event.max_attendee_nb}</div>
        </div>
      </Section>
      <Section label={'Liste des participants'}>
        <DataTable
          value={event.attendees}
          emptyMessage={'Pas de participants à afficher'}
          // tableStyle={{ minWidth: '100%' }}
        >
          <Column field="email" header="Email"></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: '12rem' }}></Column>
        </DataTable>
      </Section>
      <Section label="Autres actions">
        <div className="flex flex-row">
          <Button
            key={'delete'}
            label={"Supprimer l'évènement"}
            className="!w-54"
            outlined
            onClick={() => confirmDeletion(event)}
            severity="danger"
          />
        </div>
      </Section>
    </div>
  );
}

export default EventDetail;
