import React from 'react';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { useState, useEffect } from 'react';
import PageWrapper from '../../../../components/Display/PageWrapper';
import { DataView } from 'primereact/dataview';
import ResourceItem from './ResourceItem';
import { useNavigationContext } from '../../../../contexts/NavigationContext';
import ResourceDetail from './ResourceDetail';
import ResourcePanel from './ResourcePanel';
import DistributionManager from '../distribution/DistributionManager';
import ImagePanel from '../../common/image/ImagePanel';
import NewsPanel from '../news/NewsPanel';

function ResourcePage({ isActive }) {
  const { openResourceDetail, navigationContext, openProgramDetail } = useNavigationContext();
  const { accountContext } = useAccountContext();
  const [resourceList, setResourceList] = useState([]);
  const [resourceDetail, setResourceDetail] = useState(undefined);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [imageManagerIsOpen, setImageManagerIsOpen] = useState(false);
  const [newsManagerIsOpen, setNewsManagerIsOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(undefined);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [distribManagerIsOpen, setDistribManagerIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchResourceList = async () => {
    await privateFetch('GET', 'banquetceleste', `/cu/${accountContext.id}/resource/`).then(
      (res) => {
        setResourceList(res);
      }
    );
  };

  useEffect(() => {
    fetchResourceList();
  }, []);

  const handleClickResource = (resource) => {
    openResourceDetail(resource);
  };

  const handleClickProgram = (program) => {
    openProgramDetail(program);
  };

  useEffect(() => {
    if (navigationContext.acitveResourceId) {
      fetchResourceDetail();
    } else setResourceDetail(undefined);
  }, [navigationContext]);

  const fetchResourceDetail = async () => {
    await privateFetch(
      'GET',
      'banquetceleste',
      `/cu/${accountContext.id}/resource/${navigationContext.acitveResourceId}`
    ).then((res) => {
      setResourceDetail(res);
    });
  };

  const handleOpenManager = () => {
    setPanelIsOpen(true);
  };

  const createResource = async (resourceForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'banquetceleste',
      `/cu/${accountContext.id}/resource/`,
      'La ressource a bien été ajouté',
      JSON.stringify(resourceForm)
    ).then(() => {
      fetchResourceList();
      setPanelIsOpen(false);
      setLoading(false);
    });
  };

  const updateResource = async (resourceForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'banquetceleste',
      `/cu/${accountContext.id}/resource/${resourceDetail.id}/`,
      'La ressource a bien été modifié',
      JSON.stringify(resourceForm)
    ).then(() => {
      fetchResourceList();
      setPanelIsOpen(false);
      fetchResourceDetail(resourceDetail.id);
      setLoading(false);
    });
  };

  const createDistribution = async (distributionForm) => {
    await privateFetch(
      'POST',
      'banquetceleste',
      `/cu/${accountContext.id}/resource/${resourceDetail.id}/distribution/`,
      'La distribution a bien été ajouté',
      JSON.stringify(distributionForm)
    ).then(() => {
      fetchResourceDetail();
    });
  };

  const deleteDistribution = async (distribution) => {
    await privateFetch(
      'DELETE',
      'banquetceleste',
      `/cu/${accountContext.id}/distribution/${distribution.id}/`,
      'La distribution a bien été supprimé'
    ).then(() => {
      fetchResourceDetail();
    });
  };

  const handleDistributionOrderChange = async (distributions) => {
    const payload = distributions.map((d, index) => ({
      ...d,
      order: index
    }));
    await privateFetch(
      'PUT',
      'banquetceleste',
      `/cu/${accountContext.id}/resource/${resourceDetail.id}/distribution/`,
      "L'ordre de la distribution a bien été modifié",
      JSON.stringify(payload)
    ).then(() => {
      fetchResourceDetail();
    });
  };

  const handleDeleteResource = async (resource) => {
    await privateFetch(
      'DELETE',
      'banquetceleste',
      `/cu/${accountContext.id}/resource/${resource.id}/`,
      'La ressource a bien été supprimé'
    ).then(() => {
      fetchResourceList();
      setResourceDetail(undefined);
      setLoading(false);
    });
  };

  const handleCreateImage = () => {
    setSelectedImage(undefined);
    setImageManagerIsOpen(true);
  };

  const handleUpdateImage = (image) => {
    setSelectedImage(image);
    setImageManagerIsOpen(true);
  };

  const uploadResourceImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'banquetceleste',
      `/cu/${accountContext.id}/resource/${resourceDetail.id}/image/`,
      "L'image a bien été enregistrée",
      imageForm,
      false,
      true
    ).then(() => {
      fetchResourceDetail();
      setLoading(false);
      setImageManagerIsOpen(false);
    });
  };

  const updateImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/image/${selectedImage.id}/`,
      "L'image a bien été modifiée",
      imageForm,
      false,
      true
    ).then(() => {
      fetchResourceDetail();
      setLoading(false);
      setImageManagerIsOpen(false);
    });
  };

  const handleDeleteImage = async (image) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/image/${image.id}/`,
      "L'image a bien été supprimée"
    ).then(() => {
      fetchResourceDetail();
      setSelectedImage(undefined);
    });
  };

  const handleCreateNews = () => {
    setSelectedNews(undefined);
    setNewsManagerIsOpen(true);
  };

  const handleUpdateNews = (news) => {
    setSelectedNews(news);
    setNewsManagerIsOpen(true);
  };

  const createProgramNews = async (newsForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'banquetceleste',
      `/cu/${accountContext.id}/resource/${resourceDetail.id}/news/`,
      "L'article a bien été enregistré",
      JSON.stringify(newsForm)
    ).then(() => {
      fetchResourceDetail();
      setLoading(false);
      setNewsManagerIsOpen(false);
    });
  };

  const updateNews = async (newsForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/news/${selectedNews.id}/`,
      "L'article a bien été modifiée",
      JSON.stringify(newsForm)
    ).then(() => {
      fetchResourceDetail();
      setLoading(false);
      setNewsManagerIsOpen(false);
    });
  };

  const handleDeleteNews = async (news) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/news/${news.id}/`,
      "L'article a bien été supprimée"
    ).then(() => {
      fetchResourceDetail();
      setSelectedNews(undefined);
    });
  };

  const newResourceAction = {
    onClick: handleOpenManager,
    label: 'Ajouter une ressource',
    icon: 'pi-plus'
  };

  const goBackResourceAction = {
    onClick: handleClickResource,
    label: 'Retour à la liste'
  };

  const itemTemplate = (resource) => (
    <ResourceItem
      resource={resource}
      handleClickResource={() => handleClickResource(resource)}
      handleClickProgram={handleClickProgram}
    />
  );

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      <PageWrapper
        label="Ressources"
        labelAction={handleClickResource}
        action={resourceDetail ? goBackResourceAction : newResourceAction}
        detail={resourceDetail}>
        {!resourceDetail ? (
          <DataView value={resourceList} itemTemplate={itemTemplate} />
        ) : (
          <ResourceDetail
            resource={resourceDetail}
            handleUpdateResource={handleOpenManager}
            handleUpdateDistribution={() => setDistribManagerIsOpen(true)}
            handleClickProgram={handleClickProgram}
            handleDelete={handleDeleteResource}
            handleCreateImage={handleCreateImage}
            handleUpdateImage={handleUpdateImage}
            handleDeleteImage={handleDeleteImage}
            handleCreateNews={handleCreateNews}
            handleUpdateNews={handleUpdateNews}
            handleDeleteNews={handleDeleteNews}
          />
        )}
      </PageWrapper>
      <ResourcePanel
        isOpen={panelIsOpen}
        onClose={setPanelIsOpen}
        resource={resourceDetail}
        onCreate={createResource}
        onUpdate={updateResource}
        loading={loading}
      />
      <DistributionManager
        isOpen={distribManagerIsOpen}
        onClose={setDistribManagerIsOpen}
        createDistribution={createDistribution}
        deleteDistribution={deleteDistribution}
        handleOrderChange={handleDistributionOrderChange}
        distributions={resourceDetail?.distributions}
        loading={loading}
      />
      <ImagePanel
        image={selectedImage}
        loading={loading}
        isOpen={imageManagerIsOpen}
        onClose={() => {
          setSelectedImage(null);
          setImageManagerIsOpen(false);
        }}
        onCreate={uploadResourceImage}
        onUpdate={updateImage}
      />

      <NewsPanel
        news={selectedNews}
        loading={loading}
        isOpen={newsManagerIsOpen}
        onClose={() => {
          setSelectedNews(null);
          setNewsManagerIsOpen(false);
        }}
        onCreate={createProgramNews}
        onUpdate={updateNews}
      />
    </div>
  );
}

export default ResourcePage;
