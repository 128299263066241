import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import ImageItem from '../../common/image/ImageItem';
import FormInput from '../../../../components/Form/FormInput';
import { CONSTANT } from '../../../../utils/constants';

function ImagePanel({ image, loading, isOpen, onCreate, onUpdate, onClose }) {
  const fileUploadRef = useRef(null);
  const [label, setLabel] = useState('');
  const [file, setFile] = useState(undefined);
  const [filePreviewUrl, setFilePreviewUrl] = useState(undefined);

  useEffect(() => {
    if (image) {
      setLabel(image.label);
      setFilePreviewUrl(image.file);
      setFile(undefined);
    } else {
      setLabel('');
      setFilePreviewUrl(undefined);
      setFile(undefined);
    }
  }, [image, isOpen]);

  const handleValidate = () => {
    const formData = new FormData();
    formData.append('label', label);
    file && formData.append('file', file);
    image ? onUpdate(formData) : onCreate(formData);
  };

  const onUpload = (event) => {
    if (event.files.length == 1) {
      const file = event.files[0];
      setFilePreviewUrl(file.objectURL);
      setFile(file);
    }
  };

  const handleRemoveImage = () => {
    setFile(undefined);
    setFilePreviewUrl(undefined);
    fileUploadRef.current.clear();
  };

  const formIsValid = () => {
    return filePreviewUrl;
  };

  const customHeader = (
    <React.Fragment>
      <h2 style={{ marginBottom: 0 }} className="text-slate-900 text-2xl font-medium">
        {`${image ? 'Modifier' : 'Ajouter'} une image`}
      </h2>
    </React.Fragment>
  );

  return (
    <Sidebar
      style={{ width: 1000 }}
      header={customHeader}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={onClose}
      position="right">
      <div className="flex flex-row h-full">
        {/* Preview */}
        <div className="flex flex-col fixed justify-between">
          <div className="flex flex-col gap-y-1">
            <label className="ml-1 text-sm" htmlFor="name">
              Prévisualisation
            </label>
            <ImageItem
              key={'preview'}
              previewMode
              imageClassName={'w-[400px]'}
              image={{
                label,
                file: filePreviewUrl
              }}
              alt={'Prévisualisation de la photo'}
            />
          </div>
          <div className="w-full mt-5">
            <Button
              key={'validate'}
              label={`${image ? 'Modifier' : 'Uploader'} l'image`}
              className="w-full"
              loading={loading}
              disabled={!formIsValid()}
              onClick={handleValidate}
              severity="success"
            />
          </div>
        </div>
        <div className="flex flex-col w-full ml-[430px]">
          {/* Form */}
          <div className="flex flex-col gap-y-5 pb-20">
            <FormInput key={'file'} label={'Image'} required isValid={filePreviewUrl}>
              <div className="flex flex-row gap-x-3">
                {filePreviewUrl && (
                  <Button
                    key={'delete-img'}
                    label={"Supprimer l'image"}
                    outlined
                    onClick={handleRemoveImage}
                    severity="danger"
                  />
                )}
                <FileUpload
                  ref={fileUploadRef}
                  auto
                  disabled={file}
                  mode="basic"
                  name="file"
                  accept="image/*"
                  maxFileSize={CONSTANT.maxImageSize}
                  customUpload
                  uploadHandler={onUpload}
                  chooseLabel="Depuis mon ordinateur"
                />
              </div>
            </FormInput>
            <FormInput key={'label'} label={'Label'}>
              <InputText
                id="label"
                value={label}
                placeholder="Veuillez saisir le sous-titre de l'image"
                onChange={(e) => setLabel(e.target.value)}
              />
            </FormInput>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}

export default ImagePanel;
