import React from 'react';
import { AccountContextProvider } from './AccountContext';
import { NavigationContextProvider } from './NavigationContext';
import { ToastContextProvider } from './ToastContext';

const AppContexts = ({ children }) => (
  <AccountContextProvider>
    <ToastContextProvider>
      <NavigationContextProvider>{children}</NavigationContextProvider>
    </ToastContextProvider>
  </AccountContextProvider>
);

export default AppContexts;
