import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import CaeterraHome from '../caeterra/Home';

function Router() {
  return (
    <Routes>
      <Route exact path="/caeterra/home" element={<CaeterraHome />} />
    </Routes>
  );
}

export default Router;
