import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../common/navbar/Navbar';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import Welcome from './Welcome';
import ShowPage from './show/ShowPage';

function Home() {
  const { t } = useTranslation();
  const { navigationContext } = useNavigationContext();
  return (
    <div className="flex flex-row h-screen w-screen">
      <div className="min-w-64 w-64">
        <Navbar />
      </div>
      <div className="w-full p-5 h-auto overflow-scroll">
        {<ShowPage isActive={navigationContext.acitveAppKey === 'show'} />}
        {<Welcome isActive={!navigationContext.acitveAppKey} />}
      </div>
    </div>
  );
}

export default Home;
