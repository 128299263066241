import React, { useState } from 'react';

import './Data.scss';
import Tag from './Tag';

function DataTag({ label, tag, onClick, large, rounded }) {
  const emptyValue = <p className="text-slate-400 italic font-extralight">{'Non renseigné'}</p>;
  return (
    <div className={`flex flex-col gap-y-1 data-container ${large ? 'w-[340px]' : 'w-40'}`}>
      <span className="text-slate-500 text-sm">{label}</span>
      <div className="flex">
        {tag?.label ? (
          <Tag
            tag={{
              label: tag.label,
              bgColor: tag.bgColor,
              icon: tag.icon
            }}
            onClick={onClick}
            rounded={rounded}
          />
        ) : (
          emptyValue
        )}
      </div>
    </div>
  );
}

export default DataTag;
