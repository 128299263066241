import React from 'react';
import { Tooltip } from 'primereact/tooltip';

function FormInput({ label, required, isValid, tooltipMessage, helpMessage, children }) {
  return (
    <div className={`flex flex-col w-full`}>
      <div className="flex flex-row w-full justify-between items-center py-1">
        <div className="flex flex-row gap-x-3 items-center">
          <label>{label}</label>
          {required && (
            <span
              data-pr-tooltip={tooltipMessage || 'Champs obligatoire'}
              className={`pi custom-tooltip-label cursor-pointer ${
                isValid ? 'pi-check-circle text-green-700' : 'pi-info-circle text-orange-500'
              }`}></span>
          )}
        </div>
        {helpMessage && (
          <small id="username-help" className="italic">
            {helpMessage}
          </small>
        )}
      </div>
      {children}
      <Tooltip target=".custom-tooltip-label" />
    </div>
  );
}

export default FormInput;
