import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import FormInput from '../../../../components/Form/FormInput';
import { InputText } from 'primereact/inputtext';
import RichTextEditor from '../../../../components/Form/RichTextEditor';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';

function ProjectPanel({ isOpen, onClose, project, loading, onUpdate, onCreate }) {
  const [title, setTitle] = useState('');
  const [date, setDate] = useState(undefined);
  const [city, setCity] = useState('');
  const [client, setClient] = useState('');
  const [clientUrl, setClientUrl] = useState('');
  const [photoCredits, setPhotoCreditts] = useState('');
  const [isHighlight, setIsHighlight] = useState(false);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (project) {
      setTitle(project.title);
      setDate(new Date(project.date));
      setCity(project.city);
      setClient(project.client);
      setClientUrl(project.client_url);
      setPhotoCreditts(project.photo_credits);
      setIsHighlight(project.is_highlight);
      setDescription(project.description);
    } else {
      setTitle('');
      setDate(new Date(moment()));
      setCity('');
      setClient('');
      setClientUrl('');
      setPhotoCreditts('');
      setIsHighlight(false);
      setDescription('');
    }
  }, [project, isOpen]);

  const handleValidate = () => {
    const projectPayload = {
      title,
      date: moment(date).format('YYYY-MM-DD'),
      city,
      client,
      client_url: clientUrl,
      photo_credits: photoCredits,
      is_highlight: isHighlight,
      description
    };
    project ? onUpdate(projectPayload) : onCreate(projectPayload);
  };

  const formIsValid = () => {
    return title.length && date && description.length && client && city;
  };

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Valider la ${project ? 'modification' : 'création'}`}
        className="w-full"
        onClick={handleValidate}
        severity="success"
        disabled={!formIsValid()}
        loading={loading}
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 900 }}
      visible={isOpen}
      position="right"
      onHide={() => onClose(false)}
      header={
        <h3 className="text-slate-800 text-2xl font-medium">{`${project ? 'Modifier' : 'Ajouter'} un projet`}</h3>
      }>
      <div className="sidebar-content-container">
        <div className="form-wrapper !pb-60">
          <FormInput label={'Titre du projet'} required isValid={title.length}>
            <InputText
              id="title"
              value={title}
              maxLength={300}
              placeholder="Veuillez saisir le titre du projet"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormInput>
          <FormInput key="date" label={'Date du projet'} required isValid={date}>
            <Calendar
              id="date"
              onChange={(e) => setDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date de publication"
              value={date}
              locale="fr"
            />
          </FormInput>
          <FormInput label={'Ville du projet'} required isValid={city.length > 3}>
            <InputText
              id="city"
              value={city}
              maxLength={300}
              placeholder="Veuillez saisir la ville du projet"
              onChange={(e) => setCity(e.target.value)}
            />
          </FormInput>
          <FormInput label={'Nom du client'} required isValid={client.length}>
            <InputText
              id="client"
              value={client}
              maxLength={300}
              placeholder="Veuillez saisir le nom du client"
              onChange={(e) => setClient(e.target.value)}
            />
          </FormInput>
          <FormInput label={'Site internet du client'}>
            <InputText
              id="client_url"
              value={clientUrl}
              maxLength={300}
              placeholder="Veuillez saisir le site internet du Client"
              onChange={(e) => setClientUrl(e.target.value)}
            />
          </FormInput>
          <FormInput label={'Crédits photo'} required isValid={title.length > 3}>
            <InputText
              id="photo_credits"
              value={photoCredits}
              maxLength={300}
              placeholder="Veuillez saisir les crédits photo"
              onChange={(e) => setPhotoCreditts(e.target.value)}
            />
          </FormInput>
          <div className="card flex justify-content-center items-center">
            <Checkbox onChange={(e) => setIsHighlight(e.checked)} checked={isHighlight}></Checkbox>

            <label htmlFor="isHighlight" className="ml-2 font-semibold">
              Mettre en avant le projet
            </label>
          </div>
          <FormInput label={'Description'} required isValid={description?.length > 3}>
            <RichTextEditor
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ProjectPanel;
